import Navibar from "../../components/navigation/Navibar";
import {
    MainWrap,
    Bottom,
    BtnBetween,
    TextCenter,
    Wrapper,
    YoutubeWrap,
    LineupWrap,
    ProgramWrap,
    NoticeWrap,
    FillBar,
} from "./StMain";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import leftBtn from "../../assets/images/main/ico-roundArrow-left.svg";
import RightBtn from "../../assets/images/main/ico-roundArrow-right.svg";
import Footer from "../../components/footer/Footer";
import round from "../../assets/images/seoulcon/img-round.svg";
import triangle from "../../assets/images/program/img-triangle.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../constants";
import { useEffect, useRef, useState } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import leftArrowHover from "../../assets/images/seoulcon/ico-roundArrow-left-hover.svg";
import rightArrowHover from "../../assets/images/seoulcon/ico-roundArrow-right-hover.svg";
import { Helmet } from "react-helmet";
import Popup from "../../components/Popup";
import { formatDate } from "../../common/util-function";

interface ArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

interface Influencer {
    nameKo: string;
    nameEn: string;
    sns: string;
    follower: string;
    promotion: boolean;
    link: string;
    id: number;
    imagePath: string;
}

interface Video {
    nameKo: string;
    nameEn: string;
    titleKo: string;
    titleEn: string;
    imagePath: string;
}

export interface MainContent {
    influKo: { title: string; content: string };
    influEn: { title: string; content: string };
    programKo: { title: string; content: string };
    programEn: { title: string; content: string };
    imagePath1: { ko: string; en: string; imagePath: string };
    imagePath2: { ko: string; en: string; imagePath: string };
    imagePath3: { ko: string; en: string; imagePath: string };
    imagePath4: { ko: string; en: string; imagePath: string };
    imagePath5: { ko: string; en: string; imagePath: string };
    textKo: { title: string; content: string };
    textEn: { title: string; content: string };
}

const Main = () => {
    const [posts, setPosts] = useState([]);
    const [currentBanner, setCurrentBanner] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const imgBoxRef = useRef<HTMLDivElement>(null);
    const [isScrolling, setIsScrolling] = useState(true);
    const [dragStartX, setDragStartX] = useState(0);
    const sliderRef = useRef<Slider | null>(null);
    const [animate, setAnimate] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [banners, setBanners] = useState([
        {
            img: "",
            text: "",
            h1: "",
            link: "",
            imgMo: "",
        },
    ]);
    const [allList, setAllList] = useState<Influencer[]>([]);
    const [video, setVideo] = useState<Video>();
    const [mains, setMains] = useState<MainContent>();
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [isDrag, setIsDrag] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);
    const [metaData, setMetaData] = useState({ titleKo: "", titleEn: "", ogImage: "" });

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        const fetchPosts = async () => {
            try {
                const seo = await axios.get(`${APIURL}/manage/seo`);
                const seoData = seo.data.data;
                setMetaData({
                    titleKo: seoData.textKo.title,
                    titleEn: seoData.textEn.title,
                    ogImage: seoData.imagePath1.imagePath,
                });

                const response = await axios.get(`${APIURL}/board`);
                const res = await axios.get(`${APIURL}/manage/slide/list`);
                setPosts(response.data.data);
                const transformedData = res.data.data.map((item: any) => ({
                    img: `${APIURL}/${item.imagePath}`,
                    imgMo: item.imagePathMo,
                    text: activeLanguage === "en" ? item.titleEn : item.titleKo,
                    h1: activeLanguage === "en" ? item.nameEn : item.nameKo,
                    link: activeLanguage === "en" ? item.linkEn : item.linkKo,
                }));
                setBanners(transformedData);

                const promotion = await axios.get(`${APIURL}/influencer/promotion`);
                const allList = await axios.get(`${APIURL}/influencer/allList`);
                const video = await axios.get(`${APIURL}/manage/video`);
                const mains = await axios.get(`${APIURL}/manage/main`);
                //console.log(mains.data.data);

                setVideo(video.data.data);
                setAllList([...promotion.data.data, ...allList.data.data]);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, [activeLanguage]);

    const PrevArrow: React.FC<ArrowProps> = ({ className, style }) => (
        <div
            className={className}
            onClick={() => {
                sliderRef.current?.slickPrev();
                setCurrentBanner((prev) => (prev === 0 ? banners.length - 1 : prev - 1));
                setAnimate(true); // 바의 애니메이션을 재시작
            }}
            style={{ ...style }}
        >
            <img src={leftBtn} alt="Previous" />
            <img src={leftArrowHover} alt="Previous Hover" />
        </div>
    );

    const NextArrow: React.FC<ArrowProps> = ({ className, style }) => (
        <div
            className={className}
            onClick={() => {
                sliderRef.current?.slickNext();
                setCurrentBanner((prev) => (prev === banners.length - 1 ? 0 : prev + 1));
                setAnimate(true);
            }}
            style={{ ...style }}
        >
            <img src={RightBtn} alt="Next" />
            <img src={rightArrowHover} alt="Next Hover" />
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5900,
        pauseOnHover: false,
        initialSlide: 0,
        afterChange: (current: any) => {
            setCurrentBanner(current);
            if (!hasAnimated) {
                setHasAnimated(true);
            }
        },

        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    // bottom 타이머
    const handleAnimationEnd = () => {
        setCurrentBanner((prev) => (prev === banners.length - 1 ? 0 : prev + 1));
        setAnimate(true);
    };

    useEffect(() => {
        setAnimate(true);
    }, [currentBanner]);

    // 라인업 무한 루프
    useEffect(() => {
        const imgBox = imgBoxRef.current;
        if (imgBox) {
            const original = imgBox.querySelector(".scroll");
            if (original instanceof HTMLElement) {
                const clone = original.cloneNode(true) as HTMLElement;
                clone.classList.add("clone");
                imgBox.appendChild(clone);
            }
        }
    }, []);

    // 드래그
    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsClicked(true);
        setIsDrag(false);
        setIsDragging(true);
        setDragStartX(e.clientX);
        setScrollLeft(imgBoxRef.current?.scrollLeft ?? 0);
        e.preventDefault();
    };

    const onMouseUp = () => {
        setIsDragging(false);
        if (isClicked) {
            setIsDrag(false);
            setIsClicked(true);
        }
    };
    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDragging || !imgBoxRef.current) return;
        if (isClicked) {
            setIsDrag(true);
            setIsClicked(false);
        }
        const x = e.clientX - dragStartX;
        imgBoxRef.current.scrollLeft = scrollLeft - x;
        e.preventDefault();
    };

    const handleClick = (link: string) => {
        if (isClicked) {
            window.open(link, "_Blank");
        }
    };
    const handleMouseEnter = () => {
        const scrollElement = imgBoxRef.current?.querySelector(".scroll");
        if (scrollElement instanceof HTMLElement) {
            scrollElement.style.animationPlayState = "paused";
        }
    };

    const handleMouseLeave = () => {
        const scrollElement = imgBoxRef.current?.querySelector(".scroll");
        if (scrollElement instanceof HTMLElement) {
            scrollElement.style.animationPlayState = "running";
        }
    };

    const handleScroll = () => {
        if (!isScrolling) return;
        if (imgBoxRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = imgBoxRef.current;
            if (scrollLeft >= scrollWidth / 2) {
                imgBoxRef.current.scrollLeft = 0; // 스크롤 위치를 처음으로 초기화
            }
        }
    };

    // 루프 멈춤
    useEffect(() => {
        const handleMouseEnter = () => {
            const scrollElements = imgBoxRef.current?.querySelectorAll(".scroll, .clone");
            scrollElements?.forEach((element) => {
                (element as HTMLElement).style.animationPlayState = "paused";
            });
        };

        const handleMouseLeave = () => {
            const scrollElements = imgBoxRef.current?.querySelectorAll(".scroll, .clone");
            scrollElements?.forEach((element) => {
                (element as HTMLElement).style.animationPlayState = "running";
            });
        };

        if (imgBoxRef.current) {
            const containers = imgBoxRef.current.querySelectorAll(".imgContainer");
            containers.forEach((container) => {
                container.addEventListener("mouseenter", handleMouseEnter);
                container.addEventListener("mouseleave", handleMouseLeave);
            });

            return () => {
                containers.forEach((container) => {
                    container.removeEventListener("mouseenter", handleMouseEnter);
                    container.removeEventListener("mouseleave", handleMouseLeave);
                });
            };
        }
    }, []);

    // 스크롤 감지
    const { ref: mainRef, inView: mainView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    const { ref: youtubeRef, inView: youtubeInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
        initialInView: false,
    });

    const { ref: lineupRef, inView: lineupInView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const { ref: programRef, inView: programView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: programRef2, inView: programView2 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: programRef3, inView: programView3 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: programRef4, inView: programView4 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: programRef5, inView: programView5 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (imgBoxRef.current) {
            lineupRef(imgBoxRef.current);
        }
    }, [lineupRef, imgBoxRef]);

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth <= 550;
            setIsMobile(isMobileView);
        };

        window.addEventListener("resize", handleResize);

        // 첫 마운트시에도 실행
        handleResize();

        // 컴포넌트 언마운트시 이벤트 리스너 제거
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{metaData.titleEn}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{metaData.titleKo}</title>
                </Helmet>
            )}
            <Popup />

            <Wrapper>
                <MainWrap>
                    <Navibar />
                    <Slider ref={(slider) => (sliderRef.current = slider)} {...settings} className="banner-container">
                        {banners.map((banner: any, index) => (
                            <div key={banner.id}>
                                <div className="image-wrapper">
                                    {isMobile && banner.imgMo ? (
                                        <img
                                            src={`${APIURL}/${banner.imgMo}`}
                                            alt={banner.nameKo}
                                            className="banner-image"
                                        />
                                    ) : (
                                        <img src={banner.img} alt={banner.nameKo} className="banner-image" />
                                    )}
                                </div>
                                <TextCenter ref={mainRef}>
                                    <p
                                        className={
                                            currentBanner === index && !hasAnimated
                                                ? "animate__animated animate__fadeInUp custom-fadeInUp2"
                                                : ""
                                        }
                                    >
                                        {banner.text}
                                    </p>
                                    <h1
                                        className={
                                            currentBanner === index && !hasAnimated
                                                ? "animate__animated animate__fadeInUp custom-fadeInUp"
                                                : ""
                                        }
                                    >
                                        {banner.h1}
                                    </h1>
                                    <a href={banner.link}>
                                        <button>
                                            {activeLanguage === "en" ? "More" : "더 보기"}{" "}
                                            <img src={buttonArrow} alt="더 보기" />
                                        </button>
                                    </a>
                                </TextCenter>
                            </div>
                        ))}
                    </Slider>

                    <BtnBetween>
                        <PrevArrow className="prev" />
                        <NextArrow className="next" />
                    </BtnBetween>
                    <Bottom>
                        {banners.map((_, index) => (
                            <div className="btnbox" key={index}>
                                {currentBanner === index && (
                                    <FillBar key={currentBanner} onAnimationEnd={handleAnimationEnd} />
                                )}
                            </div>
                        ))}
                    </Bottom>
                </MainWrap>

                <YoutubeWrap>
                    <img src={round} alt="원" />
                    <h1>{activeLanguage === "en" ? video?.titleEn : video?.titleKo}</h1>
                    <p>{activeLanguage === "en" ? video?.nameEn : video?.nameKo}</p>
                    <div
                        ref={youtubeRef}
                        className={youtubeInView ? "visible animate__animated animate__fadeInUp custom-fadeInUp" : ""}
                    >
                        {video?.imagePath && (
                            <video playsInline muted autoPlay loop src={`${APIURL}/${video?.imagePath}`}></video>
                        )}
                    </div>
                </YoutubeWrap>

                <LineupWrap>
                    <div className="padding">
                        <div className="text_top">
                            <div className="left">
                                <h1>{activeLanguage === "en" ? mains?.influEn.title : mains?.influKo.title}</h1>
                                <p>{activeLanguage === "en" ? mains?.influEn.content : mains?.influKo.content}</p>
                            </div>

                            <div className="right">
                                <Link to={`/${activeLanguage}/lineup`}>
                                    {activeLanguage === "en" ? (
                                        <button>
                                            Go <img src={buttonArrowBlack} alt="detail" />
                                        </button>
                                    ) : (
                                        <button>
                                            한번에 보기 <img src={buttonArrowBlack} alt="detail" />
                                        </button>
                                    )}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`imgBox ${lineupInView ? "visible animate__animated animate__fadeIn" : ""}`}
                        ref={imgBoxRef}
                        onScroll={handleScroll}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                        onMouseMove={onMouseMove}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="scroll">
                            {allList.concat(allList).map((list, index) => (
                                <div key={index} className="imgContainer" onClick={() => handleClick(list.link)}>
                                    <div className="width">
                                        {list.imagePath && (
                                            <img src={`${APIURL}/${list.imagePath}`} alt={list.nameKo} />
                                        )}
                                    </div>
                                    <div className="text">
                                        <span className="hoverText">
                                            {activeLanguage === "En" ? list.nameEn : list.nameKo}
                                        </span>
                                        <b>{list.follower}</b>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </LineupWrap>

                <ProgramWrap>
                    <img src={triangle} alt="triangle" className="triangle" />
                    <div className="padding">
                        <div className="text2">
                            <h1>{activeLanguage === "en" ? mains?.programEn.title : mains?.programKo.title}</h1>
                            <p>{activeLanguage === "en" ? mains?.programEn.content : mains?.programKo.content}</p>
                        </div>

                        <div className="imgWrap">
                            <div
                                className={`left first ${
                                    programView ? "visible animate__animated animate__fadeInUp" : ""
                                }`}
                                ref={programRef}
                            >
                                <p>01</p>
                                <h5>{activeLanguage === "en" ? mains?.imagePath1.en : mains?.imagePath1.ko}</h5>
                                <Link to={`/${activeLanguage}/festival`}>
                                    <div className="box">
                                        {mains?.imagePath1.imagePath && (
                                            <img src={`${APIURL}/${mains?.imagePath1.imagePath}`} alt="페스티벌" />
                                        )}
                                    </div>
                                </Link>
                            </div>

                            <div
                                className={`right second ${
                                    programView2 ? "visible animate__animated animate__fadeInUp" : ""
                                }`}
                                ref={programRef2}
                            >
                                <p>02</p>
                                <h5>{activeLanguage === "en" ? mains?.imagePath2.en : mains?.imagePath2.ko}</h5>
                                <Link to={`/${activeLanguage}/conference`}>
                                    <div className="box">
                                        {mains?.imagePath2.imagePath && (
                                            <img src={`${APIURL}/${mains?.imagePath2.imagePath}`} alt="컨버런스" />
                                        )}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="imgWrap">
                            <div
                                className={`left third ${
                                    programView3 ? "visible animate__animated animate__fadeInUp" : ""
                                }`}
                                ref={programRef3}
                            >
                                <p>03</p>
                                <h5>{activeLanguage === "en" ? mains?.imagePath3.en : mains?.imagePath3.ko}</h5>
                                <Link to={`/${activeLanguage}/contents`}>
                                    <div className="box">
                                        {mains?.imagePath3.imagePath && (
                                            <img
                                                src={`${APIURL}/${mains?.imagePath3.imagePath}`}
                                                alt="콘텐츠, 패션, 뷰티"
                                            />
                                        )}
                                    </div>
                                </Link>
                            </div>

                            <div
                                className={`right fourth ${
                                    programView4 ? "visible animate__animated animate__fadeInUp" : ""
                                }`}
                                ref={programRef4}
                            >
                                <p>04</p>
                                <h5>{activeLanguage === "en" ? mains?.imagePath4.en : mains?.imagePath4.ko}</h5>
                                <Link to={`/${activeLanguage}/performance`}>
                                    <div className="box">
                                        {mains?.imagePath4.imagePath && (
                                            <img
                                                src={`${APIURL}/${mains?.imagePath4.imagePath}`}
                                                alt="공연&엔터테인멘트"
                                            />
                                        )}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="imgWrap">
                            <div
                                className={`left fifth ${
                                    programView5 ? "visible animate__animated animate__fadeInUp" : ""
                                }`}
                                ref={programRef5}
                            >
                                <p>05</p>
                                <h5>{activeLanguage === "en" ? mains?.imagePath5.en : mains?.imagePath5.ko}</h5>
                                <Link to={`/${activeLanguage}/wintafesta`}>
                                    <div className="box">
                                        {mains?.imagePath5.imagePath && (
                                            <img src={`${APIURL}/${mains?.imagePath5.imagePath}`} alt="윈터페스타" />
                                        )}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </ProgramWrap>

                <NoticeWrap>
                    <h1>{activeLanguage === "en" ? mains?.textEn.title : mains?.textKo.title}</h1>
                    <p>{activeLanguage === "en" ? mains?.textEn.content : mains?.textKo.content}</p>
                    <div className="content">
                        <div className="top">
                            <div className="left">
                                <span>{activeLanguage === "en" ? "No" : "순서"}</span>
                                <span>{activeLanguage === "en" ? "Title" : "제목"}</span>
                            </div>
                            <div className="right">
                                <span>{activeLanguage === "en" ? "Date" : "등록일"}</span>
                                <span>{activeLanguage === "en" ? "Count" : "조회수"}</span>
                            </div>
                        </div>
                        <hr />

                        <div className="listBox">
                            {posts.slice(0, 5).map((post: any, index: number) => (
                                <Link to={`/${activeLanguage}/notice/${post.id}`} key={post.id}>
                                    <div className="list">
                                        <span className="num">{posts.length - index}</span>
                                        <span className="title">
                                            {activeLanguage === "en" ? post.titleEn : post.titleKo}
                                        </span>
                                        <div className="rightBox">
                                            <span className="date">{formatDate(post.createdAt)}</span>
                                            <span className="views">
                                                <span className="viewsText">| </span>
                                                <span className="viewsText">
                                                    {activeLanguage === "en" ? "Count" : "조회수"} :{" "}
                                                </span>
                                                {post.views}
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <Link to={`/${activeLanguage}/notice/`}>
                        <button>
                            {activeLanguage === "en" ? "Detail" : "자세히 보기 "} <img src={buttonArrow} alt="detail" />
                        </button>
                    </Link>
                </NoticeWrap>

                <Footer />
            </Wrapper>
        </>
    );
};

export default Main;
