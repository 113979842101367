import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Wrapper } from "../main/StMain";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import square from "../../assets/images/info/img-square.svg";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fadeInUpAnimation } from "./GuideMap";
import bg1 from "../../assets/images/info/tourist-ko1.jpg";
import bg2 from "../../assets/images/info/tourist-ko2.jpg";
import bg3 from "../../assets/images/info/tourist-en2.jpg";

const Tourist = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [isKoHovered, setIsKoHovered] = useState(false);
    const [isEnHovered, setIsEnHovered] = useState(false);

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Tourist Sights`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-인근 관광지`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <FestivalWrap>
                    <PaddingWrap>
                        <img src={square} alt="도형" className="square" />
                        <Title>{activeLanguage === "en" ? "Tourist Sights" : "인근 관광지"}</Title>
                        <Divider />
                        <Contents>
                            <div
                                className={"box visible animate__animated animate__fadeInUp custom-fadeInUp"}
                                onMouseEnter={() => window.innerWidth > 820 && setIsKoHovered(true)}
                                onMouseLeave={() => window.innerWidth > 820 && setIsKoHovered(false)}
                            >
                                <img src={bg1} className="bg" />
                                <div className="center">
                                    <b>
                                        {activeLanguage === "en" ? (
                                            <>
                                                {"Feel free to ask me anything"}
                                                <br />
                                                {"about your trip to Seoul"}
                                            </>
                                        ) : (
                                            <>
                                                {"서울 여행, AI 챗봇에게"}
                                                <br />
                                                {"무엇이든 물어보세요"}
                                            </>
                                        )}
                                    </b>

                                    <a href="https://triple.guide/seoulcon" target="_blank">
                                        <button>
                                            {activeLanguage === "en"
                                                ? "Travel Chatbot Service"
                                                : "AI 챗봇 서비스 바로가기"}
                                            <img src={buttonArrow} alt="detail" />
                                        </button>
                                    </a>
                                </div>
                            </div>

                            <div
                                className={"box second visible animate__animated animate__fadeInUp custom-fadeInUp"}
                                onMouseEnter={() => window.innerWidth > 820 && setIsEnHovered(true)}
                                onMouseLeave={() => window.innerWidth > 820 && setIsEnHovered(false)}
                            >
                                {activeLanguage === "en" ? (
                                    <img src={bg3} className="bg" />
                                ) : (
                                    <img src={bg2} className="bg" />
                                )}

                                <div className="center">
                                    <b>
                                        {activeLanguage === "en" ? (
                                            <>
                                                {"TRIPLE global is your one"}
                                                <br />
                                                {"and only travel expert"}
                                            </>
                                        ) : (
                                            <>{"나를 아는 여행 앱, 트리플"}</>
                                        )}
                                    </b>
                                    {activeLanguage === "en" ? (
                                        <>
                                            <a
                                                href="https://triple.global/articles/60cc5ecb-587f-43af-a65b-a78d2dc5e1ea"
                                                target="_blank"
                                            >
                                                <button className="last">
                                                    About TRIPLE global
                                                    <img src={buttonArrow} alt="detail" />
                                                </button>
                                            </a>
                                        </>
                                    ) : (
                                        <a href="https://triple.guide" target="_blank">
                                            <button className="last">
                                                ‘트리플'이란?
                                                <img src={buttonArrow} alt="detail" />
                                            </button>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </Contents>
                    </PaddingWrap>
                </FestivalWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Tourist;

const FestivalWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    padding-top: 100px;
    padding-bottom: 0px;

    .square {
        position: absolute;
        top: 0px;
        left: -50px;
        z-index: 1;
    }

    @media (max-width: 820px) {
        .square {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    height: 80%;
    max-width: 1200px;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    z-index: 9;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 26px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    margin-bottom: 30px;

    @media (max-width: 550px) {
        display: none;
    }
`;

const Contents = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;
    visibility: hidden;
    position: relative;
    z-index: 99;

    div {
        ${fadeInUpAnimation}
    }

    .box {
        width: 49.5%;
        height: 360px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(47, 83, 0, 0.5) 100%);
            transition: 0.3s;
            opacity: 0;
            z-index: 1;
        }

        &:hover::after {
            opacity: 1;
        }

        .bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s, filter 0.3s;
            will-change: transform, filter;
            filter: brightness(0.3);
        }

        &:hover .bg {
            filter: none;
            transform: scale(1.1);
            transition: 0.5s;
        }

        .center {
            position: absolute;
            z-index: 2;
            width: 100%;
            display: flex;
            flex-direction: column;

            b {
                display: inline-block;
                color: rgba(191, 255, 106, 1);
                font-size: 30px;
                margin-bottom: 44px;
                line-height: 36px;
                font-family: var(--font--bold);
            }
        }

        button {
            width: 320px;
            height: 52px;
            border-radius: 50px;
            background: none;
            color: white;
            border: 1px solid white;
            font-size: 16px;
            cursor: pointer;
            transition: background-color 0.3s ease, color 0.3s ease;

            img {
                width: 12px;
                height: 12px;
                margin-left: 5px;
            }
        }

        .last {
            width: 257px;
        }

        button:hover {
            background: white;
            color: black;
        }

        button:hover img {
            content: url(${buttonArrowBlack});
        }
    }

    .visible {
        visibility: visible;
    }

    .second.animate__animated {
        animation-delay: 0.2s;
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 900px) {
        justify-content: center;
        flex-direction: column;
        visibility: hidden;

        .box {
            width: 100%;
            pointer-events: none;
            animation: fadeInUp 1s ease forwards;
            visibility: visible;

            button {
                pointer-events: auto;
            }
        }
        .custom-fadeInUp {
            animation: fadeInUp 1s ease forwards;
            transform: translateY(5%);
        }

        @keyframes fadeInUp {
            from {
                opacity: 0;
                transform: translateY(5%);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    @media (max-width: 670px) {
        .box {
            margin-top: 0px;
        }
    }

    @media (max-width: 610px) {
        .box {
            height: 300px;
            min-height: 0px;
            position: relative;
            margin-top: 0px;
            margin-bottom: 20px;

            .conImg {
                height: 300px;
                object-fit: cover;
                display: block;
            }

            .center {
                div {
                    width: 80%;
                    margin: 10px auto;
                }

                b {
                    font-size: 18px;
                    margin-bottom: 10px;
                    line-height: 28px;
                }

                button {
                    width: 300px;
                    margin-top: 10px;
                }

                div {
                    span {
                        font-size: 16px;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .box {
            margin-top: 0px;
        }
    }
`;
