import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Wrapper } from "../main/StMain";
import square from "../../assets/images/info/img-square.svg";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";
import { MainContent } from "../main/Main";
import { formatDate } from "../../common/util-function";
import DOMPurify from "isomorphic-dompurify";
import "react-quill/dist/quill.snow.css";

const NoticePage = () => {
    const { postId } = useParams();
    const [post, setPost] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const fromMain = location.state?.fromMain || false;
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [mains, setMains] = useState<MainContent>();

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPost = async () => {
            try {
                const response = await axios.get(`${APIURL}/board/${postId}`);
                const mains = await axios.get(`${APIURL}/manage/main`);
                //console.log(response.data);
                setPost(response.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, [postId]);

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-${mains?.textEn.title}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-${mains?.textKo.title}`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <FestivalWrap>
                    <PaddingWrap>
                        <img src={square} alt="도형" className="square" />
                        <Title>{activeLanguage === "en" ? post.titleEn : post.titleKo}</Title>
                        <div className="flex">
                            <p>{formatDate(post.createdAt)}</p>
                            <p>|</p>
                            <p>
                                {activeLanguage === "en" ? "Count" : "조회수"} : {post.views}
                            </p>
                        </div>
                        <Divider />
                        <Contents>
                            <p
                                className="view ql-editor"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        activeLanguage === "en"
                                            ? DOMPurify.sanitize(post.contentEn)
                                            : DOMPurify.sanitize(post.contentKo),
                                }}
                            ></p>
                            <Divider />
                            <Link to="/notice">
                                <button
                                    onClick={() => {
                                        if (fromMain) {
                                            navigate("/");
                                        } else {
                                            navigate(-1);
                                        }
                                    }}
                                >
                                    {activeLanguage === "en" ? "Back" : "돌아가기"}{" "}
                                    <img src={buttonArrow} alt="detail" />
                                </button>
                            </Link>
                        </Contents>
                    </PaddingWrap>
                </FestivalWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default NoticePage;

const FestivalWrap = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: black;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    color: white;
    position: relative;

    .square {
        position: absolute;
        top: 0px;
        left: -50px;
        z-index: 1;
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        user-select: none;
    }

    @media (max-width: 820px) {
        .square {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    height: 80%;
    max-width: 1200px;

    .flex {
        display: flex;
        z-index: 10;
        position: relative;

        p {
            margin-right: 10px;
        }
    }

    p {
        margin-top: -10px;
        margin-bottom: 30px;
    }

    @media (max-width: 820px) {
        width: 90%;
        margin-top: 90px;
    }

    @media (max-width: 550px) {
        .flex {
            p {
                font-size: 14px;
                margin-top: 0px;
            }
        }
    }
`;

const Title = styled.h1`
    font-size: 48px;
    z-index: 9;
    line-height: 4rem;
    font-family: var(--font--bold);
    position: relative;
    margin-top: 120px;

    @media (max-width: 820px) {
        margin-top: 20px;
    }

    @media (max-width: 550px) {
        font-size: 26px;
        line-height: 36px;
        margin-top: 15px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    margin-bottom: 40px;
`;

const Contents = styled.div`
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    margin-bottom: 100px;
    z-index: 10;
    position: relative;

    p {
        font-size: 18px;
        line-height: 32px;
        white-space: pre-wrap;
    }

    button {
        width: 220px;
        height: 52px;
        background: none;
        color: white;
        border: 1px solid white;
        border-radius: 50px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 50px;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    button:hover {
        background: white;
        color: black;
        transition: 0.3s;
    }

    button:hover img {
        content: url(${buttonArrowBlack});
    }
    @media (max-width: 767px) {
        img {
            width: 100%;
        }
    }

    @media (max-width: 550px) {
        margin-bottom: 100px;

        p {
            font-size: 16px;
            line-height: 28px;
        }

        button {
            margin-top: 0;
        }
        img {
            width: 100%;
        }
    }
`;
