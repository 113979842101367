import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Main from "../pages/main/Main";
import SeoulconAbout from "../pages/seoulcon/SeoulconAbout";
import Bi from "../pages/seoulcon/Bi";
import Lineup from "../pages/seoulcon/Lineup";
import Goods from "../pages/seoulcon/Goods";
import Festival from "../pages/program/festival/Festival";
import Conference from "../pages/program/conference/Conference";
import Contents from "../pages/program/contents/Contents";
import Performance from "../pages/program/performance/Performance";
import Timeline from "../pages/info/Timeline";
import Notice from "../pages/info/Notice";
import Tourist from "../pages/info/Tourist";
import Attend from "../pages/attend/Attend";
import NoticePage from "../pages/info/NoticePage";
import ScrollToTop from "../components/ScrollTop";
import ProgramDetail from "../pages/program/ProgramDetail";
import Wintafesta from "../pages/program/wintafesta/Wintafesta";
import Popup from "../components/Popup";
import { useEffect, useState } from "react";
import path from "path";

function Router() {
    const [activeLanguage, setActiveLanguage] = useState<string>("");

    useEffect(() => {
        console.log(window.location.pathname.split("/"));
        const path = window.location.pathname.split("/");
        if (path[1] === "") {
            setActiveLanguage("ko");
            sessionStorage.setItem("lang", "ko");
        } else {
            setActiveLanguage(path[1]);
            sessionStorage.setItem("lang", path[1]);
        }
    }, []);
    console.log(activeLanguage);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Navigate replace to={activeLanguage} />} />
                <Route path="/ko" element={<Main />} />
                <Route path="/ko/about" element={<SeoulconAbout />} />
                <Route path="/ko/bi" element={<Bi />} />
                <Route path="/ko/lineup" element={<Lineup />} />
                <Route path="/ko/goods" element={<Goods />} />
                <Route path="/ko/festival" element={<Festival />} />
                <Route path="/ko/conference" element={<Conference />} />
                <Route path="/ko/contents" element={<Contents />} />
                <Route path="/ko/performance" element={<Performance />} />
                <Route path="/ko/wintafesta" element={<Wintafesta />} />
                <Route path="/ko/timeline" element={<Timeline />} />
                <Route path="/ko/notice" element={<Notice />} />
                <Route path="/ko/tourist" element={<Tourist />} />
                <Route path="/ko/attend" element={<Attend />} />
                <Route path="/ko/:category/:postId" element={<ProgramDetail />} />
                <Route path="/ko/notice/:postId" element={<NoticePage />} />
                {/* 영어 */}
                <Route path="/en" element={<Main />} />
                <Route path="/en/about" element={<SeoulconAbout />} />
                <Route path="/en/bi" element={<Bi />} />
                <Route path="/en/lineup" element={<Lineup />} />
                <Route path="/en/goods" element={<Goods />} />
                <Route path="/en/festival" element={<Festival />} />
                <Route path="/en/conference" element={<Conference />} />
                <Route path="/en/contents" element={<Contents />} />
                <Route path="/en/performance" element={<Performance />} />
                <Route path="/en/wintafesta" element={<Wintafesta />} />
                <Route path="/en/timeline" element={<Timeline />} />
                <Route path="/en/notice" element={<Notice />} />
                <Route path="/en/tourist" element={<Tourist />} />
                <Route path="/en/attend" element={<Attend />} />
                <Route path="/en/:category/:postId" element={<ProgramDetail />} />
                <Route path="/en/notice/:postId" element={<NoticePage />} />
                {/* // 기본 언어로 리다이렉트 */}
                <Route path="*" element={<Navigate replace to="/ko" />} />
                {/* <Route path="/" element={<Main />} />
                <Route path="/en/about" element={<SeoulconAbout />} />
                <Route path="/en/bi" element={<Bi />} />
                <Route path="/en/lineup" element={<Lineup />} />
                <Route path="/en/goods" element={<Goods />} />
                <Route path="/en/festival" element={<Festival />} />
                <Route path="/en/conference" element={<Conference />} />
                <Route path="/en/contents" element={<Contents />} />
                <Route path="/en/performance" element={<Performance />} />
                <Route path="/en/timeline" element={<Timeline />} />
                <Route path="/en/notice" element={<Notice />} />
                <Route path="/en/tourist" element={<Tourist />} />
                <Route path="/en/attend" element={<Attend />} />
                <Route path="/en/:category/:postId" element={<ProgramDetail />} />
                <Route path="/en/notice/:postId" element={<NoticePage />} /> */}
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
