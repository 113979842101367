import styled from "styled-components";

export const FooterWrap = styled.div`
    width: 100%;
    background: #ff0e14;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden;
    white-space: pre-wrap;

    .top {
        position: relative;
        top: 20px;
        width: 100%;
        height: 210px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .info {
            width: 90%;
            position: absolute;
            top: 68px;
            left: -10px;
            text-align: left;
            line-height: 22.4px;
        }

        img {
            width: 120px;
            padding-left: 1.5rem;
        }

        p {
            padding-right: 2rem;
            font-size: 14px;
            text-align: right;
            margin-top: 0;
            line-height: 20px;
            margin-left: 30px;
        }
    }

    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: #ff0e14;
        padding-left: 2.5rem;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 999;

        .right {
            padding-right: 3rem;

            a {
                img {
                    margin-left: 10px;
                    margin-bottom: 0px;
                    cursor: pointer;
                }
            }
        }
    }
    @media (max-width: 1051px) {
        .top {
            padding-bottom: 20px;
        }
    }
    @media (max-width: 820px) {
        .top {
            padding-bottom: 40px;
        }
    }
    @media (max-width: 604px) {
        .top {
            padding-bottom: 60px;
        }
    }
    @media (max-width: 556px) {
        .top {
            padding-bottom: 80px;
        }
    }

    @media (max-width: 550px) {
        .top {
            top: -40px;
            left: 0px;
            border: none;
            height: 220px;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 65px;

            p {
                font-size: 12px;
                text-align: left;
                margin-top: 10px;
                margin-left: 22px;
            }

            .info {
                top: 150px;
                left: 0px;
            }
        }

        .bottom {
            height: 70px;
            bottom: 0px;
            left: -18px;
            font-size: 12px;
            flex-direction: column;
            align-items: flex-start;

            .right {
                position: absolute;
                bottom: 10px;

                img:first-child {
                    margin-left: -5px;
                    margin-right: 15px;
                }
            }
        }
    }

    @media (max-width: 520px) {
        .top {
            padding-bottom: 85px;
        }
    }
    @media (max-width: 478px) {
        .top {
            padding-bottom: 140px;
        }
    }

    /* @media (max-width: 464px) {
        .top {
            top: -35px;
        }
    }

    @media (max-width: 390px) {
        .top {
            padding-bottom: 90px;
        }
    } */

    @media (max-width: 380px) {
        .top {
            padding-bottom: 165px;
        }
    }
`;
