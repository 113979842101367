import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Wrapper } from "../main/StMain";
import square from "../../assets/images/info/img-square.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";
import { MainContent } from "../main/Main";
import { formatDate } from "../../common/util-function";

type Notice = {
    id: number;
    titleKo: string;
    titleEn: string;
    date: string;
    views: number;
    createdAt: string;
};

const Notice = () => {
    const [posts, setPosts] = useState<Notice[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRange, setPageRange] = useState([1, 5]);
    const itemsPerPage = 10;

    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [mains, setMains] = useState<MainContent>();

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = posts.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(posts.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };

    const goToNextPage = () => {
        setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    };

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${APIURL}/board`);
                //console.log(response.data);
                // const sortedPosts = response.data.data.sort((a: Notice, b: Notice) => {
                //     return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                // });
                const mains = await axios.get(`${APIURL}/manage/main`);

                setPosts(response.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-${mains?.textEn.title}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-${mains?.textKo.title}`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <FestivalWrap>
                    <PaddingWrap>
                        <img src={square} alt="도형" className="square" />
                        <Title>{activeLanguage === "en" ? mains?.textEn.title : mains?.textKo.title}</Title>
                        <Divider />
                        <Contents>
                            <div className="top">
                                <div className="left">
                                    <span>{activeLanguage === "en" ? "No" : "순서"}</span>
                                    <span>{activeLanguage === "en" ? "Title" : "제목"}</span>
                                </div>
                                <div className="right">
                                    <span>{activeLanguage === "en" ? "Date" : "등록일"}</span>
                                    <span>{activeLanguage === "en" ? "Count" : "조회수"}</span>
                                </div>
                            </div>
                            <Divider2 />
                            <div className="listBox">
                                {currentItems.map((post, index) => (
                                    <Link to={`/${activeLanguage}/notice/${post.id}`} key={post.id}>
                                        <div className="list">
                                            <span className="num">{currentItems.length - index}</span>
                                            <span className="title">
                                                {activeLanguage === "en" ? post.titleEn : post.titleKo}
                                            </span>
                                            <div className="rightBox">
                                                <span className="date">{formatDate(post.createdAt)}</span>
                                                <span className="views">
                                                    <span className="viewsText">| </span>
                                                    <span className="viewsText">
                                                        {activeLanguage === "en" ? "Count" : "조회수"} :{" "}
                                                    </span>
                                                    {post.views}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <Pagination>
                                <button onClick={goToPreviousPage} className="left">
                                    &lt;
                                </button>

                                {Array.from({ length: 5 }).map((_, index) => {
                                    const pageNum = index + pageRange[0];
                                    if (pageNum <= totalPages) {
                                        return (
                                            <button
                                                key={pageNum}
                                                className="num"
                                                onClick={() => setCurrentPage(pageNum)}
                                                style={{
                                                    color: currentPage === pageNum ? "white" : "rgba(86, 86, 86, 1)",
                                                }}
                                            >
                                                {pageNum}
                                            </button>
                                        );
                                    }
                                    return null;
                                })}

                                <button onClick={goToNextPage} className="right">
                                    &gt;
                                </button>
                            </Pagination>
                        </Contents>
                    </PaddingWrap>
                </FestivalWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Notice;

const FestivalWrap = styled.div`
    width: 100%;
    height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    padding-top: 100px;
    padding-bottom: 0px;

    .square {
        position: absolute;
        top: 0px;
        left: -50px;
        z-index: 1;
    }

    @media (max-width: 820px) {
        .square {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    height: 80%;
    max-width: 1200px;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    z-index: 9;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 26px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;

    @media (max-width: 550px) {
        display: none;
    }
`;

const Divider2 = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    margin: 0;
    margin-top: 10px;
`;

const Contents = styled.div`
    width: 100%;
    position: relative;
    z-index: 10;

    .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        .left {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: start;

            span:first-child {
                flex: 0 0 80px;
                margin-left: 10px;
            }

            span:nth-child(2) {
                margin-left: 15px;
            }
        }

        .right {
            width: 20%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span:first-child {
                flex: 0 0 100px;
                text-align: left;
            }

            span:nth-child(2) {
                flex: 0 0 130px;
                text-align: center;
            }
        }
        span {
            color: white;
        }
    }

    hr {
        border-top: 1px solid white;
        margin: 0px 0;
    }

    .listBox {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .list {
            display: flex;
            align-items: center;
            width: 100%;
            height: 65px;
            border-bottom: 1px solid rgba(58, 58, 58, 1);
            cursor: pointer;

            .rightBox {
                width: 260px;
                display: flex;
                justify-content: space-between;

                .viewsText {
                    display: none;
                }
            }

            &:hover {
                background: #171717;
                transition: 0.1s;
            }

            .num {
                flex: 0 1 50px;
                text-align: center;
                margin-left: 20px;
            }

            .title {
                flex: 1;
                text-align: left;
                padding-left: 55px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .date {
                margin-right: 0px;
                padding-left: 50px;
            }

            .views {
                padding-right: 21px;
            }
        }

        span {
            color: white;
        }
    }

    @media (max-width: 550px) {
        font-size: 14px;
        margin-bottom: 100px;

        .top {
            display: none;
        }

        .listBox {
            .list {
                width: 100%;
                height: 70px;
                margin: 0;
                align-items: flex-start;
                flex-direction: column;
                position: relative;

                .num {
                    display: none;
                }

                .title {
                    position: absolute;
                    top: 13px;
                    left: -55px;
                }

                .rightBox {
                    width: 100%;
                    position: absolute;
                    bottom: 13px;
                    justify-content: start;

                    .date {
                        color: rgba(171, 171, 171, 1);
                        padding-left: 0px;
                        padding-right: 65px;
                    }

                    .views {
                        color: rgba(171, 171, 171, 1);
                        .viewsText {
                            display: inline;
                            color: rgba(171, 171, 171, 1);
                        }

                        .viewsText:first-child {
                            margin-left: -50px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
`;

const Pagination = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .num {
        background: none;
        border: none;
        color: rgba(86, 86, 86, 1);
        cursor: pointer;
        margin: 6px;
    }

    .left,
    .right {
        width: 30px;
        height: 30px;
        background: none;
        border: 1px solid white;
        cursor: pointer;
        font-size: 1.1rem;
        color: white;
        margin: 0 10px;
    }
`;
