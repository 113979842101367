import styled from "styled-components";
import { Wrapper } from "../../main/StMain";
import Navibar from "../../../components/navigation/Navibar";
import Footer from "../../../components/footer/Footer";
import Triangle from "../../../assets/images/main/img-triangle.svg";
import buttonArrow from "../../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../../assets/images/main/button-arrow-black.svg";
import dateEn from "../../../assets/images/program/img-date-en.svg";
import dateKo from "../../../assets/images/program/img-date-ko.svg";
import locationEn from "../../../assets/images/program/img-location-en.svg";
import locationKo from "../../../assets/images/program/img-location-ko.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../../constants";
import { compareYear, formatDate, formatDateDay, formatDateToUS, formatEndTime } from "../../../common/util-function";
import { useInView } from "react-intersection-observer";
import { MainContent } from "../../main/Main";
import { Helmet } from "react-helmet";

const Wintafesta = () => {
    const [posts, setPosts] = useState([]);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [mains, setMains] = useState<MainContent>();

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${APIURL}/program/wintafesta`);
                const mains = await axios.get(`${APIURL}/manage/main`);

                setPosts(response.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, []);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-${mains?.imagePath5.en}`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-${mains?.imagePath5.ko}`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <FestivalWrap>
                    <PaddingWrap>
                        <img src={Triangle} alt="도형" className="Triangle" />
                        {/* <Title>{activeLanguage === "en" ? "Winta Festa" : "윈터페스타"}</Title> */}
                        <Title>{activeLanguage === "en" ? mains?.imagePath5.en : mains?.imagePath5.ko}</Title>
                        <Divider />
                        <Contents>
                            {posts.map((post: any, index) => (
                                <div
                                    key={post.id}
                                    // className={`box ${inView ? "visible animate__animated animate__fadeInUp" : ""}`}
                                    className={`box visible animate__animated animate__fadeInUp`}
                                    ref={ref}
                                    style={{ animationDelay: `${index * 0.1}s` }}
                                >
                                    <img src={`${APIURL}/${post.imagePath}`} alt={post.titleKo} className="conImg" />

                                    <div className="center">
                                        <b>{activeLanguage === "en" ? post.titleEn : post.titleKo}</b>
                                        <div>
                                            <img
                                                src={activeLanguage === "en" ? dateEn : dateKo}
                                                alt="일정"
                                                style={{ width: "55px" }}
                                            />
                                            <span>
                                                {activeLanguage === "en" ? (
                                                    <>
                                                        {formatDateToUS(post.date)}
                                                        {post.endDate
                                                            ? ` ~ ${compareYear(
                                                                  formatDateToUS(post.date),
                                                                  formatDateToUS(post.endDate)
                                                              )}`
                                                            : null}{" "}
                                                    </>
                                                ) : (
                                                    <>
                                                        {formatDateDay(post.date)}
                                                        {post.endDate
                                                            ? ` ~ ${compareYear(
                                                                  formatDateDay(post.date),
                                                                  formatDateDay(post.endDate)
                                                              )}`
                                                            : null}{" "}
                                                    </>
                                                )}

                                                {post.startTime && `${formatEndTime(post.startTime)} ~ `}
                                                {post.endTime && formatEndTime(post.endTime)}
                                            </span>
                                        </div>
                                        <div>
                                            <img
                                                src={activeLanguage === "en" ? locationEn : locationKo}
                                                alt="장소"
                                                style={{ width: activeLanguage === "en" ? "90px" : "55px" }}
                                            />
                                            <span>{activeLanguage === "en" ? post.placeEn : post.placeKo}</span>
                                        </div>
                                        <Link to={`${post.id}`}>
                                            <button>
                                                {activeLanguage === "en" ? "Go" : "바로가기"}
                                                <img src={buttonArrow} alt="detail" />
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </Contents>
                    </PaddingWrap>
                </FestivalWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Wintafesta;

const FestivalWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    background: black;
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    overflow: hidden;

    .Triangle {
        position: absolute;
        top: -100px;
        left: 60px;
    }

    @media (max-width: 820px) {
        .Triangle {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    height: 100%;
    max-width: 1544px;
    margin-top: 95px;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    line-height: 60px;
    color: white;
    position: relative;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 26px;
        line-height: 35px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    margin-bottom: 30px;
`;

const Contents = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;
    visibility: hidden;

    .visible {
        visibility: visible;
    }

    .animate__animated {
        animation-delay: 0.1s;
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .box {
        width: 49.5%;
        height: 360px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(47, 83, 0, 0.5) 100%);
            transition: 0.3s;
            opacity: 0;
            z-index: 1;
        }

        &:hover::after {
            opacity: 1;
        }

        .conImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.5s, filter 0.3s;
            will-change: transform, filter;
            filter: brightness(0.3);
        }

        &:hover .conImg {
            filter: none;
            transform: scale(1.1);
            transition: 0.5s;
        }

        .center {
            position: absolute;
            z-index: 2;
            width: 100%;

            b {
                display: inline-block;
                color: rgba(191, 255, 106, 1);
                font-size: 30px;
                margin-bottom: 30px;
                line-height: 43px;
                font-family: var(--font--bold);
            }

            div {
                width: 100% !important;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                gap: 8px;

                span {
                    line-height: 28px;
                }
            }
        }

        button {
            width: 220px;
            height: 52px;
            border-radius: 50px;
            background: none;
            color: white;
            border: 1px solid white;
            font-size: 16px;
            cursor: pointer;
            margin-top: 35px;

            img {
                width: 12px;
                height: 12px;
                margin-left: 5px;
            }
        }

        button:hover {
            background: white;
            color: black;
            transition: 0.2s;
        }

        button:hover img {
            content: url(${buttonArrowBlack});
        }
    }

    @media (max-width: 900px) {
        justify-content: center;
        flex-direction: column;
        visibility: hidden;

        .box {
            width: 100%;
            pointer-events: none;
            animation: fadeInUp 1s ease forwards;
            visibility: visible;

            button {
                pointer-events: auto;
            }
        }
        .custom-fadeInUp {
            animation: fadeInUp 1s ease forwards;
            transform: translateY(5%);
        }

        @keyframes fadeInUp {
            from {
                opacity: 0;
                transform: translateY(5%);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    @media (max-width: 670px) {
        .box {
            margin-top: 0px;
        }
    }

    @media (max-width: 610px) {
        .box {
            height: 300px;
            min-height: 0px;
            position: relative;
            margin-top: 0px;
            margin-bottom: 20px;

            .conImg {
                height: 300px;
                object-fit: cover;
                display: block;
            }

            .center {
                div {
                    width: 80%;
                    margin: 10px auto;
                }

                b {
                    font-size: 18px;
                    margin-bottom: 10px;
                    line-height: 28px;
                }

                button {
                    margin-top: 10px;
                }

                div {
                    span {
                        font-size: 16px;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .box {
            margin-top: 0px;
        }
    }
`;
