import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Wrapper } from "../main/StMain";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import pentagon from "../../assets/images/info/img-pentagon.svg";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import en from "../../assets/images/info/img-foreigner-default.png";
import ko from "../../assets/images/info/img-korean-default.png";
import bg from "../../assets/images/info/bg-attend.jpg";
import koHover from "../../assets/images/info/img-korean-hover-yellow.png";
import enHover from "../../assets/images/info/img-foreigner-hover-yellow.png";
import { Helmet } from "react-helmet";
import { fadeInUpAnimation } from "../info/GuideMap";
import axios from "axios";
import { APIURL } from "../../constants";
import { MainContent } from "../main/Main";

// Types

const statusColors = {
    Pending: "#BFFF6A",
    Soldout: "#FF0E14",
    Unable: "#3a3a3a",
    Sale: "#9339ff",
    None: "",
    NoneLink: "",
};

const statusFontColors = {
    Pending: "black",
    Soldout: "white",
    Unable: "white",
    Sale: "white",
    None: "",
    NoneLink: "",
};

const statusTextColors = {
    Pending: "white",
    Soldout: "white",
    Unable: "#565656",
    Sale: "white",
    None: "",
    NoneLink: "",
};

// Define status translations
const statusTranslations = {
    Pending: { en: "Open Later", ko: "오픈 예정" },
    Soldout: { en: "Soldout", ko: "매진 완료" },
    Unable: { en: "Unable", ko: "신청 불가" },
    Sale: { en: "On Sale", ko: "신청 가능" },
    None: { en: "", ko: "" },
    NoneLink: { en: "", ko: "" },
};

const Attend = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [isKoHovered, setIsKoHovered] = useState(false);
    const [isEnHovered, setIsEnHovered] = useState(false);
    const [festivals, setFestival] = useState([]);
    const [conferences, setConference] = useState([]);
    const [contents, setContent] = useState([]);
    const [entertainments, setEntertainment] = useState([]);
    const [wintafestas, setWintafesta] = useState([]);
    const [mains, setMains] = useState<MainContent>();

    const { ref: appRef, inView: appView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const festival = await axios.get(`${APIURL}/program/festival`);
                const conference = await axios.get(`${APIURL}/program/conference`);
                const content = await axios.get(`${APIURL}/program/content`);
                const entertainment = await axios.get(`${APIURL}/program/entertainment`);
                const wintafesta = await axios.get(`${APIURL}/program/wintafesta`);
                const mains = await axios.get(`${APIURL}/manage/main`);

                setFestival(festival.data.data);
                setConference(conference.data.data);
                setContent(content.data.data);
                setEntertainment(entertainment.data.data);
                setWintafesta(wintafesta.data.data);
                setMains(mains.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, []);

    const statusFunc = (status: string, lang: string): any => {
        console.log(status);
    };

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Attend`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-신청하기`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <FestivalWrap>
                    <PaddingWrap>
                        <img src={pentagon} alt="도형" className="pentagon" />
                        <Title>{activeLanguage === "en" ? "Attend" : "신청하기"}</Title>
                        <Divider />
                        {/* <Contents>
                            {activeLanguage === "en" ? (
                                <div
                                    ref={appRef}
                                    className={
                                        appView
                                            ? "box second visible animate__animated animate__fadeInUp custom-fadeInUp"
                                            : ""
                                    }
                                    onMouseEnter={() => window.innerWidth > 820 && setIsEnHovered(true)}
                                    onMouseLeave={() => window.innerWidth > 820 && setIsEnHovered(false)}
                                >
                                    <div className="image-container">
                                        <img src={en} alt="그 외 국적 소유자" className="language-image base" />
                                        <img
                                            src={enHover}
                                            alt="그 외 국적 소유자"
                                            className={`language-image hover ${isEnHovered ? "show" : ""}`}
                                        />
                                    </div>
                                    <p>{activeLanguage === "en" ? "Foreigner" : "그 외 국적 소유자"}</p>
                                    <a href="https://onoffmix.com/ch/enseoulcon" target="_blank">
                                        <button>
                                            {activeLanguage === "en" ? "SEOULCon Attend" : "서울콘 신청"}
                                            <img src={buttonArrow} alt="detail" />
                                        </button>
                                    </a>
                                </div>
                            ) : (
                                <div
                                    ref={appRef}
                                    className={
                                        appView ? "box visible animate__animated animate__fadeInUp custom-fadeInUp" : ""
                                    }
                                    onMouseEnter={() => window.innerWidth > 820 && setIsKoHovered(true)}
                                    onMouseLeave={() => window.innerWidth > 820 && setIsKoHovered(false)}
                                >
                                    <div className="image-container">
                                        <img src={ko} alt="한국 국적 소유자" className="language-image base" />
                                        <img
                                            src={koHover}
                                            alt="한국 국적 소유자"
                                            className={`language-image hover ${isKoHovered ? "show" : ""}`}
                                        />
                                    </div>
                                    <p>{activeLanguage === "en" ? "Korean" : "한국 국적 소유자"}</p>
                                    <a href="https://onoffmix.com/ch/krseoulcon" target="_blank">
                                        <button>
                                            {activeLanguage === "en" ? "SEOULCon Attend" : "서울콘 신청"}
                                            <img src={buttonArrow} alt="detail" />
                                        </button>
                                    </a>
                                </div>
                            )}

                        </Contents> */}
                        <Container>
                            <Titles>{activeLanguage === "en" ? mains?.imagePath1.en : mains?.imagePath1.ko}</Titles>

                            <List>
                                {festivals.map((value: any) => {
                                    const statusKey = value.status as keyof typeof statusColors; // Ensure statusKey is a valid key
                                    const status = statusTranslations[statusKey]
                                        ? activeLanguage === "en"
                                            ? statusTranslations[statusKey].en
                                            : statusTranslations[statusKey].ko
                                        : "Unknown";
                                    const backgroundColor = statusColors[statusKey] || "#000"; // Fallback color
                                    return (
                                        <>
                                            {value.status === "None" || value.status === "Unable" ? (
                                                <ListItem key={value.id}>
                                                    <SubTitle status={statusKey}>
                                                        {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                    </SubTitle>
                                                    <StatusLabel
                                                        status={statusKey}
                                                        style={{ background: backgroundColor }}
                                                    >
                                                        {status}
                                                    </StatusLabel>
                                                </ListItem>
                                            ) : (
                                                <a
                                                    href={activeLanguage === "en" ? value.linkEn : value.linkKo}
                                                    target="_blank"
                                                    key={value.id}
                                                >
                                                    <ListItem key={value.id}>
                                                        <SubTitle status={statusKey}>
                                                            {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                        </SubTitle>
                                                        <StatusLabel
                                                            status={statusKey}
                                                            style={{ background: backgroundColor }}
                                                        >
                                                            {status}
                                                        </StatusLabel>
                                                    </ListItem>
                                                </a>
                                            )}
                                        </>
                                    );
                                })}
                            </List>
                            <Titles>{activeLanguage === "en" ? mains?.imagePath2.en : mains?.imagePath2.ko}</Titles>
                            <List>
                                {conferences.map((value: any) => {
                                    const statusKey = value.status as keyof typeof statusColors; // Ensure statusKey is a valid key
                                    const status = statusTranslations[statusKey]
                                        ? activeLanguage === "en"
                                            ? statusTranslations[statusKey].en
                                            : statusTranslations[statusKey].ko
                                        : "Unknown";
                                    const backgroundColor = statusColors[statusKey] || "#000"; // Fallback color

                                    return (
                                        <>
                                            {value.status === "None" || value.status === "Unable" ? (
                                                <ListItem key={value.id}>
                                                    <SubTitle status={statusKey}>
                                                        {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                    </SubTitle>
                                                    <StatusLabel
                                                        status={statusKey}
                                                        style={{ background: backgroundColor }}
                                                    >
                                                        {status}
                                                    </StatusLabel>
                                                </ListItem>
                                            ) : (
                                                <a
                                                    href={activeLanguage === "en" ? value.linkEn : value.linkKo}
                                                    target="_blank"
                                                    key={value.id}
                                                >
                                                    <ListItem key={value.id}>
                                                        <SubTitle status={statusKey}>
                                                            {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                        </SubTitle>
                                                        <StatusLabel
                                                            status={statusKey}
                                                            style={{ background: backgroundColor }}
                                                        >
                                                            {status}
                                                        </StatusLabel>
                                                    </ListItem>
                                                </a>
                                            )}
                                        </>
                                    );
                                })}
                            </List>

                            <Titles>{activeLanguage === "en" ? mains?.imagePath3.en : mains?.imagePath3.ko}</Titles>
                            <List>
                                {contents.map((value: any) => {
                                    const statusKey = value.status as keyof typeof statusColors; // Ensure statusKey is a valid key
                                    const status = statusTranslations[statusKey]
                                        ? activeLanguage === "en"
                                            ? statusTranslations[statusKey].en
                                            : statusTranslations[statusKey].ko
                                        : "Unknown";
                                    const backgroundColor = statusColors[statusKey] || "#000"; // Fallback color

                                    return (
                                        <>
                                            {value.status === "None" || value.status === "Unable" ? (
                                                <ListItem key={value.id}>
                                                    <SubTitle status={statusKey}>
                                                        {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                    </SubTitle>
                                                    <StatusLabel
                                                        status={statusKey}
                                                        style={{ background: backgroundColor }}
                                                    >
                                                        {status}
                                                    </StatusLabel>
                                                </ListItem>
                                            ) : (
                                                <a
                                                    href={activeLanguage === "en" ? value.linkEn : value.linkKo}
                                                    target="_blank"
                                                    key={value.id}
                                                >
                                                    <ListItem key={value.id}>
                                                        <SubTitle status={statusKey}>
                                                            {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                        </SubTitle>
                                                        <StatusLabel
                                                            status={statusKey}
                                                            style={{ background: backgroundColor }}
                                                        >
                                                            {status}
                                                        </StatusLabel>
                                                    </ListItem>
                                                </a>
                                            )}
                                        </>
                                    );
                                })}
                            </List>
                            <Titles>{activeLanguage === "en" ? mains?.imagePath4.en : mains?.imagePath4.ko}</Titles>

                            <List>
                                {entertainments.map((value: any) => {
                                    const statusKey = value.status as keyof typeof statusColors; // Ensure statusKey is a valid key
                                    const status = statusTranslations[statusKey]
                                        ? activeLanguage === "en"
                                            ? statusTranslations[statusKey].en
                                            : statusTranslations[statusKey].ko
                                        : "Unknown";
                                    const backgroundColor = statusColors[statusKey] || "#000"; // Fallback color

                                    return (
                                        <>
                                            {value.status === "None" || value.status === "Unable" ? (
                                                <ListItem key={value.id}>
                                                    <SubTitle status={statusKey}>
                                                        {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                    </SubTitle>
                                                    <StatusLabel
                                                        status={statusKey}
                                                        style={{ background: backgroundColor }}
                                                    >
                                                        {status}
                                                    </StatusLabel>
                                                </ListItem>
                                            ) : (
                                                <a
                                                    href={activeLanguage === "en" ? value.linkEn : value.linkKo}
                                                    target="_blank"
                                                    key={value.id}
                                                >
                                                    <ListItem key={value.id}>
                                                        <SubTitle status={statusKey}>
                                                            {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                        </SubTitle>
                                                        <StatusLabel
                                                            status={statusKey}
                                                            style={{ background: backgroundColor }}
                                                        >
                                                            {status}
                                                        </StatusLabel>
                                                    </ListItem>
                                                </a>
                                            )}
                                        </>
                                    );
                                })}
                            </List>
                            <Titles>{activeLanguage === "en" ? mains?.imagePath5.en : mains?.imagePath5.ko}</Titles>
                            <List>
                                {wintafestas.map((value: any) => {
                                    const statusKey = value.status as keyof typeof statusColors; // Ensure statusKey is a valid key
                                    const status = statusTranslations[statusKey]
                                        ? activeLanguage === "en"
                                            ? statusTranslations[statusKey].en
                                            : statusTranslations[statusKey].ko
                                        : "Unknown";
                                    const backgroundColor = statusColors[statusKey] || "#000"; // Fallback color

                                    return (
                                        <>
                                            {value.status === "None" || value.status === "Unable" ? (
                                                <ListItem key={value.id}>
                                                    <SubTitle status={statusKey}>
                                                        {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                    </SubTitle>
                                                    <StatusLabel
                                                        status={statusKey}
                                                        style={{ background: backgroundColor }}
                                                    >
                                                        {status}
                                                    </StatusLabel>
                                                </ListItem>
                                            ) : (
                                                <a
                                                    href={activeLanguage === "en" ? value.linkEn : value.linkKo}
                                                    target="_blank"
                                                    key={value.id}
                                                >
                                                    <ListItem key={value.id}>
                                                        <SubTitle status={statusKey}>
                                                            {activeLanguage === "en" ? value.titleEn : value.titleKo}
                                                        </SubTitle>
                                                        <StatusLabel
                                                            status={statusKey}
                                                            style={{ background: backgroundColor }}
                                                        >
                                                            {status}
                                                        </StatusLabel>
                                                    </ListItem>
                                                </a>
                                            )}
                                        </>
                                    );
                                })}
                            </List>
                        </Container>
                    </PaddingWrap>
                </FestivalWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Attend;

const FestivalWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    /* background: url(${bg}) no-repeat center center;
    background-size: cover; */
    background-color: black;
    object-fit: cover;
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    padding-top: 100px;
    padding-bottom: 0px;

    .pentagon {
        position: absolute;
        top: 0px;
        left: -50px;
    }

    @media (max-width: 820px) {
        .pentagon {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    height: 80%;
    max-width: 1200px;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    z-index: 9;
    position: relative;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 26px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
`;

const Contents = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 48px;
    height: auto;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 18px;

    div {
        ${fadeInUpAnimation}
    }

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        visibility: visible;
        width: 364px;
        height: 470px;
        border: 2px solid transparent;
        border: 2px solid;
        background: rgba(0, 0, 0, 0.6);
        border-image: linear-gradient(180deg, #fffe5c 0%, rgba(255, 254, 92, 0) 100%);

        border-image-slice: 1;
        background-origin: border-box;
        background-clip: content-box, border-box;

        @media (max-width: 960px) {
            width: 47%;
        }
    }

    .image-container {
        position: relative;
        width: 100%;
        height: 205px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 220px;
        }
    }

    .language-image {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s ease;
        max-width: 100%;
        height: auto;
    }

    .visible {
        visibility: visible;
    }

    .second.animate__animated {
        animation-delay: 0.2s;
    }

    p {
        font-size: 30px;
        line-height: 30px;
        font-family: var(--font--bold);
        text-align: center;
        padding-top: 20px;

        @media (max-width: 550px) {
            font-size: 18px;
        }
    }

    button {
        width: 239px;
        height: 52px;
        background: none;
        color: white;
        border: 1px solid white;
        border-radius: 50px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;

        img {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }

    @media (min-width: 821px) {
        .language-image.hover {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.2s ease;
        }

        .language-image.hover.show {
            opacity: 1;
            visibility: visible;
        }

        a button:hover {
            background: white;
            color: black;
            transition: 0.3s;
        }

        a button:hover img {
            content: url(${buttonArrowBlack});
        }
    }

    @media (max-width: 820px) {
        .language-image.hover {
            opacity: 0;
            visibility: hidden;
        }

        .language-image.base {
            opacity: 1;
            visibility: visible;
        }

        .box {
            width: 343px;
            height: 470px;
        }
    }

    @media (max-width: 550px) {
        .box {
            width: 343px;
            height: 456px;
        }
    }
`;

// Styled components
const Container = styled.div`
    color: #fff; // White text
    min-height: 100vh; // Full height
    padding-bottom: 120px;

    a:link {
        color: white;
        text-decoration: none;
    }
    a:visited {
        color: white;
        text-decoration: none;
    }
    a:hover {
        color: white;
        text-decoration: none;
    }
    a:active {
        color: white;
        text-decoration: none;
    }
`;

const Section = styled.section`
    padding: 20px;
`;

const Titles = styled.h1`
    font-size: 40px; // Large title
    color: white; // Red color for the title
    margin-bottom: 16px; // Spacing after title
    font-family: var(--font--bold);
    padding-top: 72px;

    @media (max-width: 767px) {
        font-size: 18px; // Large title
    }
`;

const SubTitle = styled.p<{ status: keyof typeof statusColors }>`
    font-size: 18px; // Subtitle size
    margin-bottom: 10px; // Spacing after subtitle
    color: ${({ status }) => statusTextColors[status] || "white"};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 70%;
    @media (max-width: 767px) {
        font-size: 16px; // Large title
    }
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333; // Slight contrast for separation
    line-height: 28.8px;
    height: 70px;
`;

const StatusLabel = styled.span<{ status: keyof typeof statusColors }>`
    background: ${({ status }) => statusColors[status]};
    color: ${({ status }) => statusFontColors[status] || "white"}; // Fallback to white if not specified
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px; // Large title
`;
