import circle from "../../assets/images/seoulcon/img-round.svg";
import brandImg from "../../assets/images/seoulcon/img-brand.jpg";
import { Wrapper } from "../main/StMain";
import Navibar from "../../components/navigation/Navibar";
import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import appImg1 from "../../assets/images/seoulcon/img-application1.jpg";
import appImg2 from "../../assets/images/seoulcon/img-application2.jpg";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { Helmet } from "react-helmet";

const Bi = () => {
    const [titleKo, setTitleKo] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentKo, setContentKo] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [type1Ko, setType1Ko] = useState<any>({});
    const [type2Ko, setType2Ko] = useState<any>({});
    const [type1En, setType1En] = useState<any>({});
    const [type2En, setType2En] = useState<any>({});
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    const [previewUrl, setPreviewUrl] = useState("");
    const [previews, setPreviews] = useState<any>([]);

    const imgRef = useRef<HTMLImageElement | null>(null); // img 요소 참조를 위한 ref
    const [animateImg, setAnimateImg] = useState<boolean>(false); // img 애니메이션 상태

    const { ref: biRef, inView: biView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    const { ref: appRef, inView: appView } = useInView({
        triggerOnce: true,
        threshold: 0.01,
        initialInView: false,
    });
    useEffect(() => {
        const fetchPost = async () => {
            setActiveLanguage(sessionStorage.getItem("lang") || "ko");

            try {
                const response = await axios.get(`${APIURL}/manage/bi`);
                //console.log(response.data.data);
                const result = response.data.data;
                setTitleKo(result.titleKo);
                setTitleEn(result.titleEn);
                setType1Ko({
                    brand: result.type1Ko.brand,
                    title: result.type1Ko.title,
                    content: result.type1Ko.content,
                });
                setType2Ko({
                    brand: result.type2Ko.brand,
                    title1: result.type2Ko.title1,
                    content1: result.type2Ko.content1,
                    color1: result.type2Ko.color1,
                    tag1: result.type2Ko.tag1,
                    title2: result.type2Ko.title2,
                    content2: result.type2Ko.content2,
                    color2: result.type2Ko.color2,
                    tag2: result.type2Ko.tag2,
                });
                setType1En({
                    brand: result.type1En.brand,
                    title: result.type1En.title,
                    content: result.type1En.content,
                });
                setType2En({
                    brand: result.type2En.brand,
                    title1: result.type2En.title1,
                    content1: result.type2En.content1,
                    color1: result.type2En.color1,
                    tag1: result.type2En.tag1,
                    title2: result.type2En.title2,
                    content2: result.type2En.content2,
                    color2: result.type2En.color2,
                    tag2: result.type2En.tag2,
                });
                setContentKo(result.contentKo);
                setContentEn(result.contentEn);
                setPreviewUrl(`${APIURL}/${result.imagePath}`);
                const imagePaths = JSON.parse(result.imagePaths);
                const imageUrls = imagePaths.map((path: any) => `${APIURL}/${path.path}`);
                setPreviews(imageUrls);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, []);

    const handleScroll = (): void => {
        if (!imgRef.current) return;

        const position: number = window.pageYOffset;
        const isPc: boolean = window.innerWidth > 820;

        // console.log(position);

        // PC에서는 스크롤이 500 이상, 모바일에서는 700 이상일 때 애니메이션 실행
        if ((isPc && position >= 760) || (!isPc && position >= 870)) {
            setAnimateImg(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-BI`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-브랜드`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <BiWrap>
                    <img src={circle} alt="도형" className="circle" />

                    <ContentSection>
                        <Title>{activeLanguage === "en" ? titleEn : titleKo}</Title>
                        <Divider />
                        <FlexContainer>
                            <SubTitle>{activeLanguage === "en" ? type1En.brand : type1Ko.brand}</SubTitle>
                            <DescriptionWrapper>
                                <Description>{activeLanguage === "en" ? type1En.title : type1Ko.title}</Description>
                                <Description2>
                                    {activeLanguage === "en" ? type1En.content : type1Ko.content}
                                </Description2>
                                <BrandSymbol>
                                    <img
                                        src={previewUrl}
                                        alt="브랜드 심볼"
                                        ref={biRef}
                                        className={
                                            biView ? "visible animate__animated animate__fadeInUp custom-fadeInUp" : ""
                                        }
                                    />
                                </BrandSymbol>
                            </DescriptionWrapper>
                        </FlexContainer>
                    </ContentSection>

                    <ContentSection2>
                        <FlexContainer>
                            <Divider2 />
                            <SubTitle>{activeLanguage === "en" ? type2En.brand : type2Ko.brand}</SubTitle>
                            <DescriptionWrapper>
                                <Description>{activeLanguage === "en" ? type2En.title1 : type2Ko.title1}</Description>
                                <Description2 style={{ marginBottom: "50px" }}>
                                    {activeLanguage === "en" ? type2En.content1 : type2Ko.content1}
                                </Description2>
                                <Description>{activeLanguage === "en" ? type2En.title2 : type2Ko.title2}</Description>
                                <Description2 style={{ marginBottom: "50px" }}>
                                    {activeLanguage === "en" ? type2En.content2 : type2Ko.content2}
                                </Description2>
                                <FlexWrap>
                                    <div className="brandColor">
                                        <div className="color" />
                                        <div style={{ marginRight: "100px" }} className="colorText">
                                            <p>{activeLanguage === "en" ? type2En.color1 : type2Ko.color1}</p>
                                            <p className="tag">
                                                {activeLanguage === "en" ? type2En.tag1 : type2Ko.tag1}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="brandColor">
                                        <div className="colorRed" />
                                        <div className="colorText">
                                            <p>{activeLanguage === "en" ? type2En.color2 : type2Ko.color2}</p>
                                            <p className="tag">
                                                {activeLanguage === "en" ? type2En.tag2 : type2Ko.tag2}
                                            </p>
                                        </div>
                                    </div>
                                </FlexWrap>
                            </DescriptionWrapper>
                        </FlexContainer>
                    </ContentSection2>

                    <ContentSection3>
                        <FlexContainer>
                            <Divider3 />
                            <SubTitle>{activeLanguage === "en" ? contentEn : contentKo}</SubTitle>
                            <DescriptionWrapper style={{ visibility: "hidden" }}>
                                {previews.map((preview: any, index: number) => (
                                    <div key={index}>
                                        {/* <img
                                            src={preview}
                                            alt={`Preview ${index}`}
                                            className={`left ${
                                                appView ? "visible animate__animated animate__fadeInUp" : ""
                                            }`}
                                            ref={appRef}
                                        /> */}
                                        <img
                                            src={preview}
                                            alt={`Preview ${index}`}
                                            ref={imgRef}
                                            className={animateImg ? "visible animate__animated animate__fadeInUp" : ""}
                                            style={{ animationDelay: `${index * 0.5}s` }} // 인덱스를 사용하여 지연 시간 계산
                                        />
                                    </div>
                                ))}
                            </DescriptionWrapper>
                        </FlexContainer>
                    </ContentSection3>
                </BiWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Bi;

const BiWrap = styled.div`
    width: 100%;
    height: auto;
    background: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    padding-top: 200px;

    .circle {
        position: absolute;
        top: -200px;
        left: 100px;
    }

    @media (max-width: 820px) {
        .circle {
            display: none;
        }
    }
`;

const ContentSection = styled.div`
    width: 80%;
    max-width: 1544px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const ContentSection2 = styled.div`
    width: 80%;
    max-width: 1544px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 80px;

    @media (max-width: 820px) {
        width: 90%;
        height: auto;
    }
`;

const ContentSection3 = styled.div`
    width: 80%;
    max-width: 1544px;
    height: auto;
    display: flex;
    position: relative;
    top: 150px;
    margin-bottom: 250px;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    margin-bottom: 50px;
    position: absolute;
    top: -100px;
    left: 0;
    font-family: var(--font--bold);

    @media (max-width: 820px) {
        top: -100px;
    }

    @media (max-width: 550px) {
        font-size: 26px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    position: absolute;
    top: 15px;
    left: 0;

    @media (max-width: 550px) {
        top: -30px;
    }
`;

const Divider2 = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    position: absolute;
    top: 10px;
    left: 0;

    @media (max-width: 820px) {
        top: 10px;
    }

    @media (max-width: 550px) {
        top: -30px;
    }
`;

const Divider3 = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    position: absolute;
    top: 10px;
    left: 0;

    @media (max-width: 820px) {
        top: 10px;
    }

    @media (max-width: 550px) {
        top: -30px;
    }
`;

const FlexContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;

    @media (max-width: 820px) {
        flex-direction: column;
    }
`;

const SubTitle = styled.h5`
    width: 40%;
    font-size: 30px;
    align-items: flex-start;
    margin-top: 60px;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 18px;
        margin-top: 15px;
    }
`;

const DescriptionWrapper = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 60px;

    .visible {
        visibility: visible;
    }

    .second.animate__animated {
        animation-delay: 0.3s;
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    img {
        width: 100%;
        margin-bottom: 20px;
    }

    @media (max-width: 820px) {
        width: 100%;
        margin-top: 0;

        img {
            max-width: 100%;
        }
    }
`;

const Description = styled.p`
    margin-top: 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 16px;
    }
`;

const Description2 = styled.p`
    font-size: 18px;
    line-height: 30px;
    margin-top: 0px;
    white-space: pre-wrap;

    @media (max-width: 550px) {
        font-size: 16px;
        line-height: 25px;
    }
`;

const BrandSymbol = styled.div`
    width: 100%;
    align-self: flex-start;
    margin-top: 30px;
    max-width: 100%;
    object-fit: cover;
    visibility: hidden;

    .visible {
        visibility: visible;
    }

    animate__animated {
        animation-delay: 0.1s;
    }

    .custom-fadeInUp {
        animation: fadeInUp 1s ease forwards;
        transform: translateY(15%);
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(15%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 820px) {
        max-width: 100%;
    }
`;

const FlexWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    position: relative;

    .brandColor {
        display: flex;
        align-items: center;
        height: auto;

        .colorText {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .tag {
                margin-top: 10px;
            }
        }

        .colorText p {
            margin: 0;
        }

        .color {
            min-width: 48px;
            max-width: 48px;
            min-height: 48px;
            max-height: 48px;
            background: black;
            border: 2px solid white;
            margin-right: 20px;
        }
        .colorRed {
            min-width: 48px;
            max-width: 48px;
            min-height: 48px;
            max-height: 48px;
            background: red;
            border: 2px solid black;
            margin-right: 20px;
        }
    }

    .brandColor .colorText p {
        margin: 0;
    }

    .tag {
        color: rgba(143, 143, 143, 1);
        font-size: 14px;
    }

    @media (max-width: 820px) {
        width: 100%;
    }

    @media (max-width: 550px) {
        flex-direction: column;

        .brandColor {
            width: 100%;
            margin-bottom: 20px;

            .colorText {
                p:first-child {
                    font-size: 16px;
                }

                p:last-child {
                    font-size: 11.3px;
                }
            }
        }
    }
`;
