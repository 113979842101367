import logoFooter from "../../assets/images/main/logo-footer.svg";
import icoInsta from "../../assets/images/main/ico-insta.svg";
import icoMail from "../../assets/images/main/ico-mail.svg";
import icoPhone from "../../assets/images/main/ico-phone.svg";
import { FooterWrap } from "./StFooter";
import { useEffect, useState } from "react";

const Footer = () => {
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");
    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
    }, []);
    return (
        <FooterWrap>
            <div className="top">
                <img src={logoFooter} alt="logo" />
                <p className="info">
                    {activeLanguage === "en"
                        ? "Seoul Business Agency  /  400 Worldcup buk-ro (Sangam-dong), Mapo-gu, Seoul 03925, Republic of Korea\nTel: (+82)1577-7119  /  Business Registration Number: 102-82-09623  /  Personal Information Manager: Head of Contents Business\nCustomer Service Center: T. (+82)02-2677-2237 M. seoulcon@dnmd.com"
                        : "서울경제진흥원  /  03925 서울시 마포구 월드컵북로 400 (상암동)\n전화 : 1577-7119  /  사업자등록증 : 102-82-09623  /  개인정보관리책임자: 콘텐츠본부장\n서울콘 관련문의: T. 02-2677-2237  M. seoulcon@dnmd.com"}
                    {/* 서울경제진흥원 / 03925 서울시 마포구 월드컵북로 400 (상암동)
                    <br />
                    전화 : 1577-7119 / 사업자등록증 : 102-82-09623 / 개인정보관리책임자: 콘텐츠본부장 / 서울콘 관련
                    문의:(미오픈) */}
                </p>
                <p>
                    {activeLanguage === "en"
                        ? "Contents such as influencer lineup, overall timeline, and programs may be subject to change."
                        : "인플루언서 라인업, 전체 타임라인, 프로그램 등의 내용은 변동이 있을 수 있습니다."}
                </p>
            </div>
            <div className="bottom">
                <div className="left">
                    <p>Copyrights SEOULCon. All rights reserved.</p>
                </div>
                <div className="right">
                    <a href="https://www.instagram.com/seoulcon_official" target="_blank">
                        <img src={icoInsta} alt="instagram" />
                    </a>
                    <a href="tel:02-2677-2237">
                        <img src={icoPhone} alt="phone" />
                    </a>
                    <a href="mailto:seoulcon@dnmd.com">
                        <img src={icoMail} alt="mail" />
                    </a>
                </div>
            </div>
        </FooterWrap>
    );
};

export default Footer;
