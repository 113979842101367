import { Dim, NaviWrap } from "./StNavibar";
import logo from "../../assets/images/main/logo-nav.svg";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuOpen from "../../assets/images/main/ico-menu.svg";
import menuClose from "../../assets/images/main/ico-nav-close.svg";
import menuToggleOpen from "../../assets/images/main/ico-menu-close.svg";
import menuToggleClose from "../../assets/images/main/ico-menu-open.svg";
import { Wrapper } from "../../pages/main/StMain";

const Navibar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isSeoulConOpen, setIsSeoulConOpen] = useState(false);
    const [isProgramOpen, setIsProgramOpen] = useState(false);
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    const navRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    const getPathWithoutLang = (path: any) => path.replace(/^\/(ko|en)/, "");

    const currentPath = getPathWithoutLang(location.pathname);

    const isSeoulConSubMenuActive = ["/main", "/seoulcon", "/about", "/bi", "/lineup", "/goods"].includes(currentPath);

    const isProgramSubMenuActive = ["/festival", "/conference", "/contents", "/performance", "/wintafesta"].includes(
        currentPath
    );

    const isInfoSubMenuActive = ["/timeline", "/notice", "/tourist"].includes(currentPath);

    const isAttendSubMenuActive = ["/attend"].includes(currentPath);

    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (navRef.current && !navRef.current.contains(event.target as Node)) {
                setMobileMenuOpen(false);
                setIsSeoulConOpen(false);
                setIsProgramOpen(false);
                setIsInfoOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        // const currentPath = window.location.pathname;
        // console.log(currentPath);
    }, [activeLanguage]);

    const handleButtonClick = (language: string) => {
        sessionStorage.setItem("lang", language);
        setActiveLanguage(language);
        const currentPath = location.pathname;
        const newPath = currentPath.replace(/^\/(ko|en)/, `/${language}`);
        document.location.href = newPath;
    };

    const refreshPage = (path: any) => {
        if (location.pathname === path) {
            navigate(0);
        }
    };
    return (
        <Wrapper>
            <Dim className={`dim ${isMobileMenuOpen ? "visible" : ""}`} onClick={() => setMobileMenuOpen(false)}></Dim>
            <NaviWrap
                ref={navRef}
                $isSeoulConOpen={isSeoulConOpen}
                $isProgramOpen={isProgramOpen}
                $isInfoOpen={isInfoOpen}
                $isScrolled={isScrolled}
            >
                <div className="padding">
                    <div className="background-blur" />
                    <div className="left">
                        <Link to={`/${activeLanguage}/`}>
                            <img
                                src={logo}
                                alt="logo"
                                style={{
                                    filter: isMobileMenuOpen ? "brightness(50%)" : "brightness(100%)",
                                }}
                                onClick={() => (window.location.href = `/${activeLanguage}`)}
                            />
                        </Link>
                    </div>

                    <button className="mobile-menu-toggle" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                        <img src={isMobileMenuOpen ? menuClose : menuOpen} alt="menuToggle" />
                    </button>

                    <div className={`right ${isMobileMenuOpen ? "open" : ""}`}>
                        <div
                            className="menu-container"
                            onMouseEnter={() => {
                                if (window.innerWidth > 820) {
                                    setIsSeoulConOpen(true);
                                    setIsProgramOpen(false);
                                    setIsInfoOpen(false);
                                }
                            }}
                            onMouseLeave={() => {
                                if (window.innerWidth > 820) {
                                    setIsSeoulConOpen(false);
                                }
                            }}
                        >
                            <button
                                className={`menuBtn ${isSeoulConSubMenuActive ? "active" : ""}`}
                                onClick={() => {
                                    if (window.innerWidth <= 820) {
                                        setIsProgramOpen(false);
                                        setIsInfoOpen(false);
                                        setIsSeoulConOpen(!isSeoulConOpen);
                                    }
                                }}
                            >
                                <span>SEOULCon</span>
                                <div>
                                    <img
                                        src={isSeoulConOpen ? menuToggleClose : menuToggleOpen}
                                        alt="메뉴 열기"
                                        className="toggle"
                                    />
                                </div>
                            </button>
                            {isSeoulConOpen && (
                                <div className="submenu">
                                    <Link
                                        to={`/${activeLanguage}/about`}
                                        onClick={() => refreshPage(`/${activeLanguage}/about`)}
                                    >
                                        <button>About</button>
                                    </Link>
                                    {/* <span>
                                        <button onClick={() => refreshPage(`/${activeLanguage}/about`)}>About</button>
                                    </span> */}

                                    <Link
                                        to={`/${activeLanguage}/bi`}
                                        onClick={() => refreshPage(`/${activeLanguage}/bi`)}
                                    >
                                        <button>BI</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/lineup`}
                                        onClick={() => refreshPage(`/${activeLanguage}/lineup`)}
                                    >
                                        <button>Influencer line up</button>
                                    </Link>

                                    {/* <Link to='/goods'>
                    <button>Goods</button>
                  </Link> */}
                                </div>
                            )}
                        </div>

                        <div
                            className="menu-container"
                            onMouseEnter={() => {
                                if (window.innerWidth > 820) {
                                    setIsProgramOpen(true);
                                    setIsSeoulConOpen(false);
                                    setIsInfoOpen(false);
                                }
                            }}
                            onMouseLeave={() => {
                                if (window.innerWidth > 820) {
                                    setIsProgramOpen(false);
                                }
                            }}
                        >
                            <button
                                className={`menuBtn2 ${isProgramSubMenuActive ? "active" : ""}`}
                                onClick={() => {
                                    if (window.innerWidth <= 820) {
                                        setIsSeoulConOpen(false);
                                        setIsInfoOpen(false);
                                        setIsProgramOpen(!isProgramOpen);
                                    }
                                }}
                            >
                                <span>Program</span>
                                <div>
                                    <img
                                        src={isProgramOpen ? menuToggleClose : menuToggleOpen}
                                        alt="메뉴 열기"
                                        className="toggle"
                                    />
                                </div>
                            </button>
                            {isProgramOpen && (
                                <div
                                    className="submenu col"
                                    onMouseEnter={() => setIsProgramOpen(true)}
                                    onMouseLeave={() => setIsProgramOpen(false)}
                                >
                                    <Link
                                        to={`/${activeLanguage}/festival`}
                                        onClick={() => refreshPage(`/${activeLanguage}/festival`)}
                                    >
                                        <button>Festival</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/conference`}
                                        onClick={() => refreshPage(`/${activeLanguage}/conference`)}
                                    >
                                        <button>Conference</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/contents`}
                                        onClick={() => refreshPage(`/${activeLanguage}/contents`)}
                                    >
                                        <button>Content/Fashion/Beauty</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/performance`}
                                        onClick={() => refreshPage(`/${activeLanguage}/performance`)}
                                    >
                                        <button>Performance/Entertainment</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/wintafesta`}
                                        onClick={() => refreshPage(`/${activeLanguage}/wintafesta`)}
                                    >
                                        <button>Winta Festa</button>
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div
                            className="menu-container"
                            onMouseEnter={() => {
                                if (window.innerWidth > 820) {
                                    setIsInfoOpen(true);
                                    setIsSeoulConOpen(false);
                                    setIsProgramOpen(false);
                                }
                            }}
                            onMouseLeave={() => {
                                if (window.innerWidth > 820) {
                                    setIsInfoOpen(false);
                                }
                            }}
                        >
                            <button
                                className={`menuBtn3 ${isInfoSubMenuActive ? "active" : ""}`}
                                onClick={() => {
                                    if (window.innerWidth <= 820) {
                                        setIsProgramOpen(false);
                                        setIsSeoulConOpen(false);
                                        setIsInfoOpen(!isInfoOpen);
                                    }
                                }}
                            >
                                <span>Information</span>
                                <div>
                                    <img
                                        src={isInfoOpen ? menuToggleClose : menuToggleOpen}
                                        alt="메뉴 열기"
                                        className="toggle"
                                    />
                                </div>
                            </button>
                            {isInfoOpen && (
                                <div
                                    className="submenu"
                                    onMouseEnter={() => setIsInfoOpen(true)}
                                    onMouseLeave={() => setIsInfoOpen(false)}
                                >
                                    <Link
                                        to={`/${activeLanguage}/timeline`}
                                        onClick={() => refreshPage(`/${activeLanguage}/timeline`)}
                                    >
                                        <button>Timeline</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/notice`}
                                        onClick={() => refreshPage(`/${activeLanguage}/notice`)}
                                    >
                                        <button>Notice</button>
                                    </Link>

                                    <Link
                                        to={`/${activeLanguage}/tourist`}
                                        onClick={() => refreshPage(`/${activeLanguage}/tourist`)}
                                    >
                                        <button>Tourist Sights</button>
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div className="menu-container">
                            <Link
                                to={`/${activeLanguage}/attend`}
                                onClick={() => refreshPage(`/${activeLanguage}/attend`)}
                            >
                                <button className={`menuBtn4 ${isAttendSubMenuActive ? "active" : ""}`}>
                                    <span>Attend</span>
                                </button>
                            </Link>
                        </div>

                        <button
                            className={`ko ${activeLanguage === "ko" ? "active" : ""}`}
                            onClick={() => handleButtonClick("ko")}
                        >
                            KO
                        </button>
                        <hr />
                        <button
                            className={`en ${activeLanguage === "en" ? "active" : ""}`}
                            onClick={() => handleButtonClick("en")}
                        >
                            EN
                        </button>
                    </div>
                </div>
            </NaviWrap>
        </Wrapper>
    );
};

export default Navibar;
