import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Wrapper } from "../main/StMain";
import square from "../../assets/images/info/img-square.svg";
import day1Ko from "../../assets/images/info/day1ver4-ko.svg";
import day2Ko from "../../assets/images/info/day2ver4-ko.svg";
import day1En from "../../assets/images/info/day1ver4-en.svg";
import day2En from "../../assets/images/info/day2ver4-en.svg";
import time from "../../assets/images/info/img-time.svg";
import blur from "../../assets/images/info/box-blur.png";
import calendar from "../../assets/images/info/calendar-outline.svg";
import { useEffect, useRef, useState } from "react";
import GuideMap from "./GuideMap";
import { Helmet } from "react-helmet";

const Timeline = () => {
    const [mapTab, setMapTab] = useState(1);

    const scrollContainerRef = useRef<any>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startPosX, setStartPosX] = useState(0);
    const [isChangingImage, setIsChangingImage] = useState(false);

    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    const onMouseDown = (e: any) => {
        setIsDragging(true);
        setStartPosX(e.pageX - scrollContainerRef.current.offsetLeft);
        scrollContainerRef.current.style.cursor = "grabbing";
    };

    const onMouseMove = (e: any) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startPosX) * 0.05; // 스크롤 속도 조절
        scrollContainerRef.current.scrollLeft -= walk;
    };

    const onMouseUpOrLeave = () => {
        setIsDragging(false);
        scrollContainerRef.current.style.cursor = "grab";
    };

    const changeImage = async (num: number): Promise<void> => {
        setIsChangingImage(true);
        await triggerFadeOutAnimation();
        setMapTab(num);
        triggerFadeInUpAnimation();
    };

    const triggerFadeOutAnimation = (): Promise<void> => {
        return new Promise((resolve) => {
            const imageElement = document.querySelector(".box2") as HTMLElement;
            imageElement.classList.add("custom-fadeOut");
            setTimeout(() => {
                imageElement.classList.remove("custom-fadeOut");
                resolve();
            }, 100);
        });
    };

    const triggerFadeInUpAnimation = (): void => {
        const imageElement = document.querySelector(".box2") as HTMLElement;
        imageElement.classList.add("custom-fadeInUp2");
        setTimeout(() => {
            imageElement.classList.remove("custom-fadeInUp2");
            setIsChangingImage(false);
        }, 500);
    };

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");
        const imageElement = document.querySelector(".box2") as HTMLElement;
        imageElement.classList.add("custom-fadeInUp2");
        setTimeout(() => {
            imageElement.classList.remove("custom-fadeInUp2");
            setIsChangingImage(false);
        }, 500);
    }, []);
    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Timeline`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-타임라인`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <FestivalWrap>
                    <PaddingWrap>
                        <img src={square} alt="도형" className="square" />
                        <Title>{activeLanguage === "en" ? "Timeline" : "전체 타임라인"}</Title>
                        <Divider />
                        <Contents>
                            <TopMenu>
                                <MenuContainer>
                                    <CalendarBox onClick={() => changeImage(1)} active={mapTab === 1}>
                                        <div>
                                            <img src={calendar} />
                                            <span>Day 01</span>
                                        </div>
                                        <div>{activeLanguage === "en" ? "2023-12-30 (Sat)" : "2023-12-30 (토)"}</div>
                                    </CalendarBox>
                                </MenuContainer>
                                <MenuContainer>
                                    <CalendarBox onClick={() => changeImage(2)} active={mapTab === 2}>
                                        <div>
                                            <img src={calendar} />
                                            <span>Day 02</span>
                                        </div>
                                        <div>{activeLanguage === "en" ? "2023-12-31 (Sun)" : "2023-12-31 (일)"}</div>
                                    </CalendarBox>
                                </MenuContainer>
                            </TopMenu>

                            <TimeLineContainer>
                                <Time>
                                    <img src={time} />
                                </Time>
                                <div
                                    ref={scrollContainerRef}
                                    onMouseDown={onMouseDown}
                                    onMouseMove={onMouseMove}
                                    onMouseUp={onMouseUpOrLeave}
                                    onMouseLeave={onMouseUpOrLeave}
                                    style={{ cursor: "grab", overflowX: "auto", overflowY: "hidden" }}
                                >
                                    <Cal>
                                        {mapTab === 1 ? (
                                            <img
                                                className={`box2`}
                                                src={activeLanguage === "en" ? day1En : day1Ko}
                                                alt="day1"
                                            />
                                        ) : (
                                            <img
                                                className={`box2`}
                                                src={activeLanguage === "en" ? day2En : day2Ko}
                                                alt="day1"
                                            />
                                        )}
                                    </Cal>
                                </div>
                                <Blur>
                                    <img src={blur} />
                                </Blur>
                            </TimeLineContainer>
                        </Contents>
                        <Title>{activeLanguage === "en" ? "Guide Map" : "가이드맵"}</Title>
                        <Divider />
                        <GuideMap />
                    </PaddingWrap>
                </FestivalWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Timeline;

const fadeInUpAnimation = `
    @keyframes fadeInUp2 {
        from { opacity: 0; transform: translateY(3%); }
        to { opacity: 1; transform: translateY(0); }
    }

    .custom-fadeInUp2 {
        animation: fadeInUp2 0.5s ease-in-out;
    }
`;

interface CalendarBoxProps {
    active: boolean;
}

const TopMenu = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const MenuContainer = styled.div`
    width: 48%;
    margin: 0 auto;
`;

const CalendarBox = styled.div<CalendarBoxProps>`
    div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;

        span {
            font-family: var(--font--bold);
        }

        img {
            margin-right: 5px;
        }
    }
    div:last-child {
        font-size: 16px;
        text-align: center;
        margin-top: 16px;
    }

    @media (min-width: 768px) and (max-width: 1439px) {
        div:last-child {
            font-size: 14px;
        }
    }

    @media (max-width: 767px) {
        div:first-child {
            font-size: 18px;
        }
        div:last-child {
            font-size: 12px;
            margin-top: 8px;
        }
    }

    opacity: ${(props) => (props.active ? 1 : 0.5)};
    cursor: pointer;
    padding-bottom: 30px;
    border-bottom: 2px solid;

    &:hover {
        opacity: 1;
        transition: opacity 0.3s;
    }
`;

const TimeLineContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 44px;
    position: relative;
`;
const Time = styled.div`
    min-width: 55px;
    @media (max-width: 768px) {
        img {
            width: 36px;
        }
    }
`;
const Cal = styled.div`
    ${fadeInUpAnimation}
    width: 100%;
    /* overflow-x: auto; */
    /* white-space: nowrap; */
    /* min-width: 2227px; */

    @media (max-width: 768px) {
        img {
            width: 1600px;
        }
    }
`;

const Blur = styled.div`
    position: absolute;
    right: 0px;

    @media (max-width: 768px) {
        img {
            width: 53px;
        }
    }
`;

const FestivalWrap = styled.div`
    width: 100%;
    height: auto;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    position: relative;

    .square {
        position: absolute;
        top: 0px;
        left: -50px;

        @media (max-width: 768px) {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 90%;
    max-width: 1544px;

    @media (max-width: 420px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    margin-top: 120px;
    z-index: 9;
    position: relative;
    font-family: var(--font--bold);

    @media (max-width: 768px) {
        font-size: 30px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    margin-bottom: 30px;
    position: relative;
`;

const Contents = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
    position: relative;

    @media (max-width: 768px) {
        /* overflow-x: auto; */
        width: 100%;
        min-width: 0;
    }
`;
