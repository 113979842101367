import styled from "styled-components";
import Footer from "../../components/footer/Footer";
import Navibar from "../../components/navigation/Navibar";
import { Wrapper } from "../main/StMain";
import star from "../../assets/images/seoulcon/img-star.svg";
import border from "../../assets/images/seoulcon/border.svg";
import tiktok from "../../assets/images/seoulcon/tiktok.svg";
import insta from "../../assets/images/seoulcon/instragram.svg";
import youtube from "../../assets/images/seoulcon/youtube.svg";
import weibo from "../../assets/images/seoulcon/weibo.svg";
import facebook from "../../assets/images/seoulcon/facebook.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import lists from "../../assets/images/seoulcon/list2.png";

interface Line {
    title: string;
    content: string;
    ambass: string;
    influ: string;
}
const Lineup = () => {
    const [promotion, setPromotion] = useState([]);
    const [list, setList] = useState([]);
    const [influKo, setInfluKo] = useState<Line>();
    const [influEn, setInfluEn] = useState<Line>();
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPost = async () => {
            try {
                const response = await axios.get(`${APIURL}/manage/lineup`);
                const result = response.data.data;
                setInfluKo({
                    title: result.influKo.title,
                    content: result.influKo.content,
                    ambass: result.influKo.ambass,
                    influ: result.influKo.influ,
                });
                setInfluEn({
                    title: result.influEn.title,
                    content: result.influEn.content,
                    ambass: result.influEn.ambass,
                    influ: result.influEn.influ,
                });
                const promotion = await axios.get(`${APIURL}/influencer/promotion`);
                setPromotion(promotion.data.data);

                const allList = await axios.get(`${APIURL}/influencer/allList`);
                setList(allList.data.data);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, []);

    const { ref: lineupRef, inView: lineupView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    const { ref: lineupRef2, inView: lineupView2 } = useInView({
        triggerOnce: true,
        threshold: 0,
        initialInView: false,
    });

    return (
        <>
            {activeLanguage === "en" ? (
                <Helmet>
                    <title>{`SEOULCon-Influencer Lineup`}</title>
                </Helmet>
            ) : (
                <Helmet>
                    <title>{`서울콘-인플루언서 라인업`}</title>
                </Helmet>
            )}
            <Wrapper>
                <Navibar />
                <LinupWrap>
                    <StarImg src={star} alt="별" />
                    <PaddingWrap>
                        <Title>{activeLanguage === "en" ? influEn?.title : influKo?.title}</Title>
                        <Ment>{activeLanguage === "en" ? influEn?.content : influKo?.content}</Ment>
                        <Divider />
                        <Ambassadors>{activeLanguage === "en" ? influEn?.ambass : influKo?.ambass}</Ambassadors>
                        <ImgBox1>
                            {promotion.map((value: any, index) => (
                                <div
                                    key={value.id}
                                    className={`first ${lineupView ? "animate__animated animate__fadeInUp" : ""}`}
                                    ref={lineupRef}
                                    style={{ animationDelay: `${index * 0.1}s` }}
                                >
                                    <a href={value.link} target="_blank" rel={value.nameKo}>
                                        <div className="width">
                                            <img src={border} alt="홍보대사" />
                                            <img
                                                src={`${APIURL}/${value.imagePath}`}
                                                alt={value.nameKo}
                                                className="influencer"
                                            />
                                        </div>
                                        <div className="sns">
                                            <img
                                                src={
                                                    value.sns === "tiktok"
                                                        ? tiktok
                                                        : value.sns === "instagram"
                                                        ? insta
                                                        : value.sns === "youtube"
                                                        ? youtube
                                                        : value.sns === "facebook"
                                                        ? facebook
                                                        : weibo
                                                }
                                                alt={value.sns}
                                            />
                                        </div>
                                    </a>
                                    <p>{activeLanguage === "en" ? value.nameEn : value.nameKo} </p>
                                    <b>{value.follower}</b>
                                </div>
                            ))}
                        </ImgBox1>

                        <Divider2 />

                        <Influencer>{activeLanguage === "en" ? influEn?.influ : influKo?.influ}</Influencer>
                        <ImgBox2>
                            {list.map((value: any, index) => (
                                <div
                                    key={value.id}
                                    // className={`first ${lineupView2 ? "animate__animated animate__fadeInUp" : ""}`}
                                    className={`first animate__animated animate__fadeInUp`}
                                    // ref={lineupRef2}
                                    style={{
                                        animationDelay: `${index * 0.1}s`,
                                    }}
                                >
                                    <a href={value.link} target="_blank" rel={value.nameKo}>
                                        <div className="width">
                                            <img
                                                src={`${APIURL}/${value.imagePath}`}
                                                alt={value.nameKo}
                                                className="influencer"
                                            />
                                        </div>
                                        <div className="sns">
                                            <img
                                                src={
                                                    value.sns === "tiktok"
                                                        ? tiktok
                                                        : value.sns === "instagram"
                                                        ? insta
                                                        : value.sns === "youtube"
                                                        ? youtube
                                                        : value.sns === "facebook"
                                                        ? facebook
                                                        : weibo
                                                }
                                                alt={value.sns}
                                            />
                                        </div>
                                    </a>
                                    <p>{activeLanguage === "en" ? value.nameEn : value.nameKo} </p>
                                    <b>{value.follower}</b>
                                </div>
                            ))}
                        </ImgBox2>
                    </PaddingWrap>
                </LinupWrap>
                <Footer />
            </Wrapper>
        </>
    );
};

export default Lineup;

const LinupWrap = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    position: relative;
    color: white;
`;

const StarImg = styled.img`
    position: absolute;
    top: -240px;
    left: -50px;

    @media (max-width: 820px) {
        display: none;
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    max-width: 1544px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 820px) {
        width: 90%;
    }
`;

const Ambassadors = styled.h1`
    font-size: 30px;
    margin-bottom: 0px;
    display: flex;
    align-self: flex-start;
    margin-top: 30px;
    z-index: 9;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 18px;
    }
`;

const Influencer = styled.h1`
    font-size: 30px;
    margin-bottom: 0px;
    display: flex;
    align-self: flex-start;
    margin-top: 30px;
    z-index: 9;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 18px;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    margin-bottom: 50px;
    display: flex;
    align-self: flex-start;
    margin-top: 130px;
    z-index: 9;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 26px;
        margin-top: 115px;
    }
`;

const Ment = styled.p`
    font-size: 18px;
    align-self: flex-start;
    margin-top: -25px;
    margin-bottom: 30px;
    z-index: 9;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 550px) {
        font-size: 16px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
`;

const Divider2 = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;

    @media (max-width: 550px) {
        margin-top: -35px;
    }
`;

const ImgBox1 = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px 25px;
    margin-top: 40px;
    margin-bottom: 80px;
    align-items: flex-start;
    visibility: hidden;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        visibility: visible;

        a {
            cursor: pointer;

            .width {
                position: relative;
                border-radius: 50%;
                width: 230px;
                height: 230px;
                overflow: hidden;
                z-index: 1;
                transition: transform 0.3s ease;

                img:first-child {
                    z-index: 2;
                    width: 100%;
                }

                img:last-child {
                    width: 100%;
                    height: auto;
                    transition: transform 0.3s ease;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    z-index: 1;
                }

                img:first-child {
                    z-index: 2;
                    width: 100%;
                }

                .influencer {
                    width: 100%;
                    height: auto;
                    transition: transform 0.3s ease;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    z-index: 1;
                }

                &:hover .influencer {
                    transform: scale(1.1);
                    z-index: 0;
                }

                &:hover::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0.6;
                    background: linear-gradient(180deg, rgba(82, 243, 255, 0) 0%, #52f3ff 100%);
                    border-radius: 50%;
                    z-index: 1;
                    transition: opacity 0.3s ease;
                }
            }
        }

        .custom-fadeInUp {
            animation: fadeInUp 1s ease forwards;
            transform: translateY(15%);
        }

        @keyframes fadeInUp {
            from {
                opacity: 0;
                transform: translateY(15%);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        p {
            font-size: 16px;
            margin-top: 5px;
            margin-bottom: 15px;
            width: 100%;
        }

        p,
        b {
            color: white;
        }

        b {
            font-family: var(--font--bold);
            color: rgba(82, 243, 255, 1);
            font-size: 30px;
        }

        .sns {
            width: 54px;
            height: 54px;
            background: white;
            border-radius: 50%;
            position: absolute;
            right: 0;
            bottom: 70px;
            z-index: 9;

            img {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }

    @media (max-width: 1450px) {
        grid-template-columns: repeat(4, 1fr);

        div {
            a {
                .width {
                    width: 200px;
                    height: 200px;

                    .influencer {
                        min-width: 200px;
                        min-height: 200px;
                    }
                }
            }
        }
    }

    @media (max-width: 1250px) {
        grid-template-columns: repeat(4, 1fr);

        div {
            a {
                .width {
                    width: 180px;
                    height: 180px;

                    .influencer {
                        min-width: 180px;
                        min-height: 180px;
                    }
                }
            }
        }
    }

    @media (max-width: 1140px) {
        grid-template-columns: repeat(3, 1fr);

        div {
            a {
                .width {
                    width: 220px;
                    height: 220px;

                    .influencer {
                        min-width: 220px;
                        min-height: 220px;
                    }
                }
            }
        }
    }

    @media (max-width: 1015px) {
        grid-template-columns: repeat(3, 1fr);

        div {
            a {
                .width {
                    width: 200px;
                    height: 200px;

                    .influencer {
                        min-width: 200px;
                        min-height: 200px;
                    }
                }
                p {
                    font-size: 15px;
                }
            }
        }
    }
    @media (max-width: 765px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 10px;

        div {
            a {
                .width {
                    width: 180px;
                    height: 180px;

                    .influencer {
                        min-width: 180px;
                        min-height: 180px;
                    }
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }

    @media (max-width: 637px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 10px;

        div {
            a {
                .width {
                    width: 165px;
                    height: 165px;

                    .influencer {
                        min-width: 165px;
                        min-height: 165px;
                    }
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }

    @media (max-width: 588px) {
        div {
            a {
                .width {
                    width: 240px;
                    height: 240px;

                    .influencer {
                        min-width: 240px;
                        min-height: 240px;
                    }
                }
            }
            .sns {
                bottom: 80px;
            }
        }
    }

    @media (max-width: 572px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 235px;
                    height: 235px;

                    .influencer {
                        min-width: 235px;
                        min-height: 235px;
                    }
                }
            }
            .sns {
                bottom: 80px;
            }
        }
    }

    @media (max-width: 561px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 230px;
                    height: 230px;

                    .influencer {
                        min-width: 230px;
                        min-height: 230px;
                    }
                }
            }
            p {
                font-size: 14px;
                margin-bottom: 10px;
            }

            b {
                font-size: 20px;
            }

            .sns {
                width: 52px;
                height: 52px;
                bottom: 70px;
            }
        }
    }

    @media (max-width: 549px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 220px;
                    height: 220px;

                    .influencer {
                        min-width: 220px;
                        min-height: 220px;
                    }
                }
            }
        }
    }

    @media (max-width: 538px) {
        gap: 20px 30px;

        div {
            a {
                .width {
                    width: 215px;
                    height: 215px;

                    .influencer {
                        min-width: 215px;
                        min-height: 215px;
                    }
                }
            }
        }
    }

    @media (max-width: 533px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 210px;
                    height: 210px;

                    .influencer {
                        min-width: 210px;
                        min-height: 210px;
                    }
                }
            }
        }
    }

    @media (max-width: 522px) {
        gap: 10px 25px;

        div {
            a {
                .width {
                    width: 205px;
                    height: 205px;

                    .influencer {
                        min-width: 205px;
                        min-height: 205px;
                    }
                }
            }
        }
    }

    @media (max-width: 499px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 195px;
                    height: 195px;

                    .influencer {
                        min-width: 195px;
                        min-height: 195px;
                    }
                }
            }
        }
    }

    @media (max-width: 472px) {
        gap: 10px 10px;

        div {
            a {
                .width {
                    width: 190px;
                    height: 190px;

                    .influencer {
                        min-width: 190px;
                        min-height: 190px;
                    }
                }
            }
        }
    }

    @media (max-width: 449px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 170px;
                    height: 170px;

                    .influencer {
                        min-width: 170px;
                        min-height: 170px;
                    }
                }
            }
        }
    }

    @media (max-width: 427px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 160px;
                    height: 160px;

                    .influencer {
                        min-width: 160px;
                        min-height: 160px;
                    }
                }
            }
        }
    }

    @media (max-width: 405px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 155px;
                    height: 155px;

                    .influencer {
                        min-width: 155px;
                        min-height: 155px;
                    }
                }
            }
        }
    }

    @media (max-width: 388px) {
        gap: 10px 25px;

        div {
            a {
                .width {
                    width: 145px;
                    height: 145px;

                    .influencer {
                        min-width: 145px;
                        min-height: 145px;
                    }
                }
            }
        }
    }

    @media (max-width: 377px) {
        gap: 10px 25px;

        div {
            a {
                .width {
                    width: 140px;
                    height: 140px;

                    .influencer {
                        min-width: 140px;
                        min-height: 140px;
                    }
                }
            }
        }
    }

    @media (max-width: 355px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 135px;
                    height: 135px;

                    .influencer {
                        min-width: 135px;
                        min-height: 135px;
                    }
                }
            }
        }
    }

    @media (max-width: 344px) {
        gap: 10px 15px;

        div {
            a {
                .width {
                    width: 135px;
                    height: 135px;

                    .influencer {
                        min-width: 135px;
                        min-height: 135px;
                    }
                }
            }
        }
    }

    @media (max-width: 333px) {
        gap: 10px 10px;

        div {
            a {
                .width {
                    width: 135px;
                    height: 135px;

                    .influencer {
                        min-width: 135px;
                        min-height: 135px;
                    }
                }
            }
        }
    }
`;

const ImgBox2 = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px 25px;
    margin-top: 40px;
    margin-bottom: 80px;
    visibility: hidden;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        visibility: visible;

        a {
            cursor: pointer;

            .width {
                position: relative;
                border-radius: 50%;
                width: 230px;
                height: 230px;
                overflow: hidden;
                z-index: 2;

                .influencer {
                    width: 100%;
                    height: 100%;
                    min-width: 230px;
                    min-height: 230px;
                    object-fit: cover;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    transition: transform 0.3s ease;
                    z-index: 1;
                }

                img:first-child {
                    z-index: 9;
                    width: 100%;
                }

                img:last-child {
                    width: 100%;
                    height: auto;
                    transition: transform 0.3s ease;
                    position: absolute;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                    z-index: 1;
                }

                &:hover .influencer {
                    transform: scale(1.1);
                    z-index: 0;
                }

                &:hover::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(82, 243, 255, 0) 0%, #52f3ff 100%);
                    opacity: 0.6;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
        }

        p {
            font-size: 16px;
            margin-top: 10px;
            margin-bottom: 15px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @media (max-width: 680px) {
                width: 130px;
            }
        }

        p,
        b {
            color: white;
        }

        b {
            font-family: var(--font--bold);
            color: rgba(82, 243, 255, 1);
            font-size: 30px;
        }

        .sns {
            width: 54px;
            height: 54px;
            background: white;
            border-radius: 50%;
            position: absolute;
            right: 0;
            bottom: 70px;
            z-index: 9;

            img {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }

    @media (max-width: 1450px) {
        grid-template-columns: repeat(4, 1fr);

        div {
            a {
                .width {
                    width: 200px;
                    height: 200px;

                    .influencer {
                        min-width: 200px;
                        min-height: 200px;
                    }
                }
            }
        }
    }

    @media (max-width: 1250px) {
        grid-template-columns: repeat(4, 1fr);

        div {
            a {
                .width {
                    width: 180px;
                    height: 180px;

                    .influencer {
                        min-width: 180px;
                        min-height: 180px;
                    }
                }
            }
        }
    }

    @media (max-width: 1140px) {
        grid-template-columns: repeat(3, 1fr);

        div {
            a {
                .width {
                    width: 220px;
                    height: 220px;

                    .influencer {
                        min-width: 220px;
                        min-height: 220px;
                    }
                }
            }
        }
    }

    @media (max-width: 1015px) {
        grid-template-columns: repeat(3, 1fr);

        div {
            a {
                .width {
                    width: 200px;
                    height: 200px;

                    .influencer {
                        min-width: 200px;
                        min-height: 200px;
                    }
                }
                p {
                    font-size: 15px;
                }
            }
        }
    }

    @media (max-width: 765px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 10px;

        div {
            a {
                .width {
                    width: 180px;
                    height: 180px;

                    .influencer {
                        min-width: 180px;
                        min-height: 180px;
                    }
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }

    @media (max-width: 637px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 10px;

        div {
            a {
                .width {
                    width: 165px;
                    height: 165px;

                    .influencer {
                        min-width: 165px;
                        min-height: 165px;
                    }
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }

    @media (max-width: 588px) {
        div {
            a {
                .width {
                    width: 240px;
                    height: 240px;

                    .influencer {
                        min-width: 240px;
                        min-height: 240px;
                    }
                }
            }
            .sns {
                bottom: 80px;
            }
        }
    }

    @media (max-width: 572px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 235px;
                    height: 235px;

                    .influencer {
                        min-width: 235px;
                        min-height: 235px;
                    }
                }
            }
            .sns {
                bottom: 80px;
            }
        }
    }

    @media (max-width: 561px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 230px;
                    height: 230px;

                    .influencer {
                        min-width: 230px;
                        min-height: 230px;
                    }
                }
            }
            p {
                font-size: 14px;
                margin-bottom: 10px;
            }

            b {
                font-size: 20px;
            }

            .sns {
                width: 52px;
                height: 52px;
                bottom: 70px;
            }
        }
    }

    @media (max-width: 549px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 220px;
                    height: 220px;

                    .influencer {
                        min-width: 220px;
                        min-height: 220px;
                    }
                }
            }
        }
    }

    @media (max-width: 538px) {
        gap: 20px 30px;

        div {
            a {
                .width {
                    width: 215px;
                    height: 215px;

                    .influencer {
                        min-width: 215px;
                        min-height: 215px;
                    }
                }
            }
        }
    }

    @media (max-width: 533px) {
        gap: 20px 20px;

        div {
            a {
                .width {
                    width: 210px;
                    height: 210px;

                    .influencer {
                        min-width: 210px;
                        min-height: 210px;
                    }
                }
            }
        }
    }

    @media (max-width: 522px) {
        gap: 10px 25px;

        div {
            a {
                .width {
                    width: 205px;
                    height: 205px;

                    .influencer {
                        min-width: 205px;
                        min-height: 205px;
                    }
                }
            }
        }
    }

    @media (max-width: 499px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 195px;
                    height: 195px;

                    .influencer {
                        min-width: 195px;
                        min-height: 195px;
                    }
                }
            }
        }
    }

    @media (max-width: 472px) {
        gap: 10px 10px;

        div {
            a {
                .width {
                    width: 190px;
                    height: 190px;

                    .influencer {
                        min-width: 190px;
                        min-height: 190px;
                    }
                }
            }
        }
    }

    @media (max-width: 449px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 170px;
                    height: 170px;

                    .influencer {
                        min-width: 170px;
                        min-height: 170px;
                    }
                }
            }
        }
    }

    @media (max-width: 427px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 160px;
                    height: 160px;

                    .influencer {
                        min-width: 160px;
                        min-height: 160px;
                    }
                }
            }
        }
    }

    @media (max-width: 405px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 155px;
                    height: 155px;

                    .influencer {
                        min-width: 155px;
                        min-height: 155px;
                    }
                }
            }
        }
    }

    @media (max-width: 388px) {
        gap: 10px 25px;

        div {
            a {
                .width {
                    width: 145px;
                    height: 145px;

                    .influencer {
                        min-width: 145px;
                        min-height: 145px;
                    }
                }
            }
        }
    }

    @media (max-width: 377px) {
        gap: 10px 25px;

        div {
            a {
                .width {
                    width: 140px;
                    height: 140px;

                    .influencer {
                        min-width: 140px;
                        min-height: 140px;
                    }
                }
            }
        }
    }

    @media (max-width: 355px) {
        gap: 10px 20px;

        div {
            a {
                .width {
                    width: 135px;
                    height: 135px;

                    .influencer {
                        min-width: 135px;
                        min-height: 135px;
                    }
                }
            }
        }
    }

    @media (max-width: 344px) {
        gap: 10px 15px;

        div {
            a {
                .width {
                    width: 135px;
                    height: 135px;

                    .influencer {
                        min-width: 135px;
                        min-height: 135px;
                    }
                }
            }
        }
    }

    @media (max-width: 333px) {
        gap: 10px 10px;

        div {
            a {
                .width {
                    width: 135px;
                    height: 135px;

                    .influencer {
                        min-width: 135px;
                        min-height: 135px;
                    }
                }
            }
        }
    }
`;
