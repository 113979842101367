import styled from "styled-components";
import red from "../../assets/images/info/red1-ko-740.svg";
import blue from "../../assets/images/info/blue1-ko-740.svg";
import yellow from "../../assets/images/info/yellow1-ko-740.svg";
import btheb from "../../assets/images/info/btheb1-ko-740.svg";
import rede from "../../assets/images/info/red1-en-740.svg";
import bluee from "../../assets/images/info/blue1-en-740.svg";
import yellowe from "../../assets/images/info/yellow1-en-740.svg";
import bthebe from "../../assets/images/info/btheb1-en-740.svg";
import redt from "../../assets/images/info/red1-ko-650.svg";
import bluet from "../../assets/images/info/blue1-ko-650.svg";
import yellowt from "../../assets/images/info/yellow1-ko-650.svg";
import bthebt from "../../assets/images/info/btheb1-ko-650.svg";
import redte from "../../assets/images/info/red1-en-650.svg";
import bluete from "../../assets/images/info/blue1-en-650.svg";
import yellowte from "../../assets/images/info/yellow1-en-650.svg";
import bthebte from "../../assets/images/info/btheb1-en-650.svg";
import redm from "../../assets/images/info/red1-ko-350.svg";
import bluem from "../../assets/images/info/blue1-ko-350.svg";
import yellowm from "../../assets/images/info/yellow1-ko-350.svg";
import bthebm from "../../assets/images/info/btheb1-ko-350.svg";
import redme from "../../assets/images/info/red1-en-350.svg";
import blueme from "../../assets/images/info/blue1-en-350.svg";
import yellowme from "../../assets/images/info/yellow1-en-350.svg";
import bthebme from "../../assets/images/info/btheb1-en-350.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";

export default function GuideMap() {
    const [guide, setGuide] = useState(1);
    const [titleKo, setTitleKo] = useState("");
    const [contentKo, setContentKo] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [imagePath, setImagePath] = useState("");
    const [color, setColor] = useState("");
    const [isChangingImage, setIsChangingImage] = useState(false);
    const [posts, setPosts] = useState([]);
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${APIURL}/manage/popup/timeline`);
                //console.log(response.data);
                setPosts(response.data.data);
            } catch (error) {
                console.error("Error fetching the posts:", error);
            }
        };
        fetchPosts();
    }, []);

    const changeImage = async (num: number): Promise<void> => {
        setIsChangingImage(true);
        await triggerFadeOutAnimation();
        setGuide(num);
        await fetchData(num);
        triggerFadeInUpAnimation();
    };

    const triggerFadeOutAnimation = (): Promise<void> => {
        return new Promise((resolve) => {
            const imageElement = document.querySelector(".box") as HTMLElement;
            imageElement.classList.add("custom-fadeOut");
            setTimeout(() => {
                imageElement.classList.remove("custom-fadeOut");
                resolve();
            }, 100);
        });
    };

    const triggerFadeInUpAnimation = (): void => {
        const imageElement = document.querySelector(".box") as HTMLElement;
        imageElement.classList.add("custom-fadeInUp");
        setTimeout(() => {
            imageElement.classList.remove("custom-fadeInUp");
            setIsChangingImage(false);
        }, 500);
    };

    const fetchData = async (num: number) => {
        try {
            const response = await axios.get(`${APIURL}/manage/timeline/${num}`);
            // console.log(response.data.data);
            const result = response.data.data;
            setTitleKo(result.titleKo);
            setContentKo(result.contentKo);
            setTitleEn(result.titleEn);
            setContentEn(result.contentEn);
            setImagePath(result.imagePath);
            switch (result.guide) {
                case 1:
                    setColor("#FF0E14");
                    break;
                case 2:
                    setColor("#52F3FF");
                    break;
                case 3:
                    setColor("#FFFE5C");
                    break;
                case 4:
                    setColor("#9336FF");
                    break;
            }
        } catch (error) {
            console.error("Can't fetch the post:", error);
        }
    };
    useEffect(() => {
        fetchData(guide);
    }, [guide]);

    return (
        <Container>
            <Mobile>
                {guide === 1 ? (
                    activeLanguage === "en" ? (
                        <img src={redme} useMap="#image_map_mobile" />
                    ) : (
                        <img src={redm} useMap="#image_map_mobile" />
                    )
                ) : guide === 2 ? (
                    activeLanguage === "en" ? (
                        <img src={blueme} useMap="#image_map_mobile" />
                    ) : (
                        <img src={bluem} useMap="#image_map_mobile" />
                    )
                ) : guide === 3 ? (
                    activeLanguage === "en" ? (
                        <img src={yellowme} useMap="#image_map_mobile" />
                    ) : (
                        <img src={yellowm} useMap="#image_map_mobile" />
                    )
                ) : activeLanguage === "en" ? (
                    <img src={bthebme} useMap="#image_map_mobile" />
                ) : (
                    <img src={bthebm} useMap="#image_map_mobile" />
                )}

                <map name="image_map_mobile">
                    <area
                        alt="red"
                        title="red"
                        coords={"115,92 16,163 5,190 30,202 137,164 162,148 138,93 "}
                        shape="polygon"
                        onClick={() => changeImage(1)}
                    />
                    <area
                        alt="blue"
                        title="blue"
                        coords={"158,2 190,38 281,97 304,102 327,93 333,59 310,38 201,2 "}
                        shape="polygon"
                        onClick={() => changeImage(2)}
                    />
                    <area
                        alt="yellow"
                        title="yellow"
                        coords={"274,112 240,135 251,153 303,145 332,128 332,109 312,104 "}
                        shape="polygon"
                        onClick={() => changeImage(3)}
                    />
                    <area
                        alt="btheb"
                        title="btheb"
                        coords={"260,170 222,176 235,200 282,208 334,195 340,168 327,154 302,164 "}
                        shape="polygon"
                        onClick={() => changeImage(4)}
                    />
                </map>
            </Mobile>
            <Tablet>
                {guide === 1 ? (
                    activeLanguage === "en" ? (
                        <img src={redte} useMap="#image_map_tablet" />
                    ) : (
                        <img src={redt} useMap="#image_map_tablet" />
                    )
                ) : guide === 2 ? (
                    activeLanguage === "en" ? (
                        <img src={bluete} useMap="#image_map_tablet" />
                    ) : (
                        <img src={bluet} useMap="#image_map_tablet" />
                    )
                ) : guide === 3 ? (
                    activeLanguage === "en" ? (
                        <img src={yellowte} useMap="#image_map_tablet" />
                    ) : (
                        <img src={yellowt} useMap="#image_map_tablet" />
                    )
                ) : activeLanguage === "en" ? (
                    <img src={bthebte} useMap="#image_map_tablet" />
                ) : (
                    <img src={bthebt} useMap="#image_map_tablet" />
                )}
                <map name="image_map_tablet">
                    <area
                        alt="red"
                        title="red"
                        coords={"206,171 57,282 11,337 35,378 124,361 261,306 301,274 272,212 251,179 "}
                        shape="polygon"
                        onClick={() => changeImage(1)}
                    />
                    <area
                        alt="blue"
                        title="blue"
                        coords={"306,6 296,19 317,51 484,153 557,191 600,173 612,113 573,74 438,31 340,0 "}
                        shape="polygon"
                        onClick={() => changeImage(2)}
                    />
                    <area
                        alt="yellow"
                        title="yellow"
                        coords={"513,204 456,239 451,276 535,279 612,250 622,213 584,192 "}
                        shape="polygon"
                        onClick={() => changeImage(3)}
                    />
                    <area
                        alt="btheb"
                        title="btheb"
                        coords={
                            "513,311 448,319 425,318 413,337 430,375 504,388 604,370 630,335 619,289 596,289 563,303 "
                        }
                        shape="polygon"
                        onClick={() => changeImage(4)}
                    />
                </map>
            </Tablet>
            <LeftBox>
                {imagePath && <img className={`box`} src={`${APIURL}/${imagePath}`} alt="map" />}
                <Hall color={color}>{activeLanguage === "en" ? titleEn : titleKo}</Hall>
                <TextBox>
                    <p>{activeLanguage === "en" ? contentEn : contentKo} </p>
                </TextBox>
            </LeftBox>
            <RightBox>
                {guide === 1 ? (
                    activeLanguage === "en" ? (
                        <img src={rede} useMap="#image_map" />
                    ) : (
                        <img src={red} useMap="#image_map" />
                    )
                ) : guide === 2 ? (
                    activeLanguage === "en" ? (
                        <img src={bluee} useMap="#image_map" />
                    ) : (
                        <img src={blue} useMap="#image_map" />
                    )
                ) : guide === 3 ? (
                    activeLanguage === "en" ? (
                        <img src={yellowe} useMap="#image_map" />
                    ) : (
                        <img src={yellow} useMap="#image_map" />
                    )
                ) : activeLanguage === "en" ? (
                    <img src={bthebe} useMap="#image_map" />
                ) : (
                    <img src={btheb} useMap="#image_map" />
                )}
                <map name="image_map">
                    <area
                        alt="red"
                        title="red"
                        coords={"240,198 72,321 9,389 26,427 225,387 340,315 293,209 "}
                        shape="polygon"
                        onClick={() => changeImage(1)}
                        style={{ cursor: "pointer" }}
                    />
                    <area
                        alt="blue"
                        title="blue"
                        coords={"336,1 361,48 598,203 655,214 689,190 700,128 661,87 525,39 396,1 "}
                        shape="polygon"
                        onClick={() => changeImage(2)}
                        style={{ cursor: "pointer" }}
                    />
                    <area
                        alt="yellow"
                        title="yellow"
                        coords={"587,230 527,279 518,312 556,316 636,303 683,278 707,254 694,223 637,220 "}
                        shape="polygon"
                        onClick={() => changeImage(3)}
                        style={{ cursor: "pointer" }}
                    />
                    <area
                        alt="btheb"
                        title="btheb"
                        coords={"570,356 485,361 468,382 485,420 563,440 664,427 713,390 710,339 687,326 644,345 "}
                        shape="polygon"
                        onClick={() => changeImage(4)}
                        style={{ cursor: "pointer" }}
                    />
                </map>
            </RightBox>
        </Container>
    );
}

export const fadeInUpAnimation = `
    @keyframes fadeInUp {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
    }

    .custom-fadeInUp {
        animation: fadeInUp 0.5s ease-in-out;
    }
`;

const Container = styled.div`
    display: flex;
    padding-top: 24px;
    justify-content: space-around;
    margin-bottom: 150px;

    /* @media (min-width: 767px) and (max-width: 1439px) {
      flex-direction: column;
    } */

    @media (max-width: 1439px) {
        flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
        padding-top: 0px;
        margin-top: 32px;
    }
`;
const Hall = styled.div`
    font-size: 30px;
    font-family: var(--font--bold);
    color: ${(props) => props.color};
    margin-top: 60px;

    @media (max-width: 767px) {
        font-size: 23px;
        margin-top: 36px;
    }
`;
const TextBox = styled.div`
    line-height: 28.8px;
    font-size: 18px;
    margin-top: 24px;
    margin-bottom: 52px;
    padding-left: 5px;

    p {
        white-space: pre-wrap;
    }

    @media (max-width: 1439px) {
        line-height: 28.8px;
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 52px;
        padding-left: 5px;
    }

    @media (max-width: 767px) {
        line-height: 25.6px;
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 52px;
        padding-left: 5px;
    }
`;
const LeftBox = styled.div`
    ${fadeInUpAnimation}
    display: flex;
    flex-direction: column;
    @media (min-width: 1439px) {
        margin-right: 100px;
    }
    img {
        width: 100%;
    }
`;
const RightBox = styled.div`
    display: flex;
    align-items: start;
    display: block;
    @media (max-width: 1439px) {
        display: none;
    }
`;
const Mobile = styled.div`
    display: none;
    @media (max-width: 767px) {
        display: block;
        text-align: center;
        img {
            width: 350px;
        }
    }
`;

const Tablet = styled.div`
    display: none;
    @media (min-width: 767px) and (max-width: 1439px) {
        display: block;
        text-align: center;
        img {
            width: 650px;
        }
    }
`;

const Desktop = styled.img`
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
`;
