import styled from "styled-components";
import { Wrapper } from "../main/StMain";
import Navibar from "../../components/navigation/Navibar";
import Footer from "../../components/footer/Footer";
import Triangle from "../../assets/images/program/img-triangle.svg";
import buttonArrow from "../../assets/images/main/button-arrow-white.svg";
import buttonArrowGreen from "../../assets/images/main/button-arrow-green.svg";
import buttonArrowBlack from "../../assets/images/main/button-arrow-black.svg";
import dateEn from "../../assets/images/program/img-date-en.svg";
import dateKo from "../../assets/images/program/img-date-ko.svg";
import locationEn from "../../assets/images/program/img-location-en.svg";
import locationKo from "../../assets/images/program/img-location-ko.svg";
import collaEn from "../../assets/images/program/img-collaboration-en.svg";
import collaKo from "../../assets/images/program/img-collaboration-ko.svg";
import descEn from "../../assets/images/program/img-description-en.svg";
import descKo from "../../assets/images/program/img-description-ko.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { APIURL } from "../../constants";
import { compareYear, formatDateDay, formatDateToUS, formatEndTime } from "../../common/util-function";
import { useInView } from "react-intersection-observer";

const ProgramDetail = () => {
    const [titleKo, setTitleKo] = useState("");
    const [contentKo, setContentKo] = useState("");
    const [placeKo, setPlaceKo] = useState("");
    const [datesKo, setDatesKo] = useState("");
    const [datesEn, setDatesEn] = useState("");
    const [endDateKo, setEndDateKo] = useState("");
    const [endDateEn, setEndDateEn] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [cooperateKo, setCooperateKo] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");
    const [attend, setAttend] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [placeEn, setPlaceEn] = useState("");
    const [cooperateEn, setCooperateEn] = useState("");
    const [linkKo, setLinkKo] = useState("");
    const [linkEn, setLinkEn] = useState("");
    const [category, setCategory] = useState("");
    const [activeLanguage, setActiveLanguage] = useState<string | null>("");

    const { postId } = useParams();

    const navi = useNavigate();

    const goBack = () => {
        navi(-1);
    };

    const onLink = (link: string) => {
        window.open(link, "_blank");
    };

    useEffect(() => {
        setActiveLanguage(sessionStorage.getItem("lang") || "ko");

        const fetchPost = async () => {
            try {
                const response = await axios.get(`${APIURL}/program/category/${postId}`);
                const result = response.data.data;
                setTitleKo(result.titleKo);
                setContentKo(result.contentKo);
                setPlaceKo(result.placeKo);
                setDatesEn(result.date && formatDateToUS(result.date));
                setDatesKo(result.date && formatDateDay(result.date));
                setEndDateEn(result.endDate && formatDateToUS(result.endDate));
                setEndDateKo(result.endDate && formatDateDay(result.endDate));
                setStartTime(result.startTime && formatEndTime(result.startTime));
                setEndTime(result.endTime && formatEndTime(result.endTime));
                setCooperateKo(result.cooperateKo);
                setPreviewUrl(`${APIURL}/${result.imagePath}`);
                setAttend(result.isAttend);
                setTitleEn(result.titleEn);
                setContentEn(result.contentEn);
                setPlaceEn(result.placeEn);
                setCooperateEn(result.cooperateEn);
                setLinkKo(result.linkKo);
                setLinkEn(result.linkEn);
                setCategory(result.category);
            } catch (error) {
                console.error("Can't fetch the post:", error);
            }
        };

        fetchPost();
    }, []);

    const { ref: appRef, inView: appView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
        initialInView: false,
    });

    return (
        <Wrapper>
            <Navibar />
            <FestivalWrap>
                <img src={previewUrl} alt="팬 페스티벌" className="bg" />
                <PaddingWrap>
                    <img src={Triangle} alt="도형" className="Triangle" />
                    <Title>{activeLanguage === "en" ? titleEn : titleKo}</Title>
                    <Divider />
                    <Contents>
                        <div className="right">
                            <div>
                                <img
                                    src={previewUrl}
                                    alt="팬 페스티벌"
                                    ref={appRef}
                                    className={
                                        appView ? "visible animate__animated animate__fadeInUp custom-fadeInUp" : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="left">
                            <img src={activeLanguage === "en" ? dateEn : dateKo} alt="일정" className="date" />
                            <p>
                                • {activeLanguage === "en" ? datesEn : datesKo}{" "}
                                {activeLanguage === "en" && endDateEn ? `~ ${compareYear(datesEn, endDateEn)} ` : null}
                                {activeLanguage === "ko" && endDateKo ? `~ ${compareYear(datesKo, endDateKo)} ` : null}
                                {startTime && `${formatEndTime(startTime)} ~ `}
                                {endTime && formatEndTime(endTime)}
                            </p>
                            <img
                                src={activeLanguage === "en" ? locationEn : locationKo}
                                alt="장소"
                                className={`date ${activeLanguage === "en" ? "date-en1" : ""}`}
                                style={{
                                    width: activeLanguage === "en" ? "110px" : "",
                                }}
                            />
                            <p>• {activeLanguage === "en" ? placeEn : placeKo}</p>
                            <img
                                src={activeLanguage === "en" ? descEn : descKo}
                                alt="내용"
                                className={`date ${activeLanguage === "en" ? "date-en2" : ""}`}
                                style={{
                                    width: activeLanguage === "en" ? "135px" : "",
                                }}
                            />
                            <p>{activeLanguage === "en" ? contentEn : contentKo}</p>
                            {activeLanguage === "en" && cooperateEn ? (
                                <>
                                    <img
                                        src={collaEn}
                                        alt="협력"
                                        className={`date ${activeLanguage === "en" ? "date-en3" : ""}`}
                                        style={{
                                            width: activeLanguage === "en" ? "150px" : "",
                                        }}
                                    />
                                    <p>{cooperateEn}</p>
                                </>
                            ) : null}
                            {activeLanguage === "ko" && cooperateKo ? (
                                <>
                                    <img src={collaKo} alt="협력" className="date" />
                                    <p>{cooperateKo}</p>
                                </>
                            ) : null}

                            <div className="btnBox">
                                {activeLanguage === "en" && linkEn ? (
                                    <button className="green" onClick={() => onLink(linkEn)}>
                                        {category === "wintafesta" ? "Go" : "Attend"}{" "}
                                        <img src={buttonArrowGreen} alt="detail" />
                                    </button>
                                ) : (
                                    ""
                                )}
                                {activeLanguage === "ko" && linkKo ? (
                                    <button className="green" onClick={() => onLink(linkKo)}>
                                        {category === "wintafesta" ? "바로가기" : "신청하기"}{" "}
                                        <img src={buttonArrowGreen} alt="detail" />
                                    </button>
                                ) : (
                                    ""
                                )}
                                <button className="back" onClick={goBack} style={{ marginBottom: "80px" }}>
                                    {activeLanguage === "en" ? "Back" : "돌아가기"}{" "}
                                    <img src={buttonArrow} alt="detail" />
                                </button>
                            </div>
                        </div>
                    </Contents>
                </PaddingWrap>
            </FestivalWrap>
            <Footer />
        </Wrapper>
    );
};

export default ProgramDetail;

const FestivalWrap = styled.div`
    width: 100%;
    min-height: 100vh;
    padding-bottom: 120px;
    background: black;
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    overflow: hidden;

    .Triangle {
        position: absolute;
        top: -100px;
        left: 60px;
    }

    .bg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.2;
        filter: blur(10px);
        -webkit-filter: blur(10px);
        position: absolute;
        z-index: 0;
    }
    p {
        white-space: pre-wrap;
    }

    @media (max-width: 820px) {
        height: auto;
        padding-bottom: 0px;

        .Triangle {
            display: none;
        }
    }
`;

const PaddingWrap = styled.div`
    width: 80%;
    height: 100%;
    max-width: 1544px;
    margin-top: 100px;

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 48px;
    line-height: 60px;
    color: rgba(191, 255, 106, 1);
    position: relative;
    font-family: var(--font--bold);

    @media (max-width: 550px) {
        font-size: 26px;
        line-height: 35px;
    }
`;

const Divider = styled.hr`
    width: 100%;
    background: rgba(58, 58, 58, 1);
    opacity: 0.3;
    margin-bottom: 30px;
`;

const Contents = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;

    .left {
        width: 45%;
        display: flex;
        flex-direction: column;
        z-index: 9;

        .btnBox {
            width: 500px;
            display: flex;
            margin-top: -50px;
        }

        p {
            line-height: 35px;
            margin-bottom: 40px;
            padding: 0;
        }

        p:nth-child(even) {
            font-size: 18px;
            font-weight: 500;
        }

        .date {
            width: 74px;
            padding: 0.05rem 0.2rem;
            display: inline-block;
            margin-bottom: 8px;
            text-align: center;
            margin-bottom: -5px;
        }

        div {
            button {
                width: 220px;
                height: 52px;
                background: none;
                color: white;
                border: 1px solid white;
                border-radius: 50px;
                font-size: 16px;
                margin-top: 100px;
                margin-bottom: 150px;
                cursor: pointer;
            }

            img {
                margin-left: 5px;

                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translateY(15%);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                .custom-fadeInUp {
                    animation: fadeInUp 1s ease forwards;
                }
            }

            .green {
                margin-right: 20px;
                border: 1px solid rgba(191, 255, 106, 1);
                color: rgba(191, 255, 106, 1);
            }
            .green:hover {
                background: rgba(191, 255, 106, 1);
                transition: 0.2s;
                color: black;
            }

            .green:hover img {
                content: url(${buttonArrowBlack});
            }

            .back:hover {
                background: white;
                transition: 0.2s;
                color: black;
            }

            .back:hover img {
                content: url(${buttonArrowBlack});
            }
        }
    }

    .right {
        width: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        z-index: 9;

        img {
            width: 100%;
        }
    }

    @media (max-width: 820px) {
        justify-content: center;
        flex-direction: column;

        .left {
            width: 100%;
            margin-top: 50px;

            .btnBox {
                a {
                    pointer-events: auto;
                }
            }
        }

        .right {
            width: 100%;
            position: static;

            img {
                width: 100%;
            }
        }
    }

    @media (max-width: 550px) {
        .left {
            font-size: 16px;

            .btnBox {
                width: 100%;
                flex-direction: column;
                margin-top: 0px;

                button {
                    width: 100%;
                    margin-top: 30px;
                }

                button:first-child {
                    margin-bottom: -10px;
                }
            }

            p:nth-child(even) {
                font-size: 16px;
            }

            .date {
                width: 63px;
            }
        }
    }

    @media (max-width: 550px) {
        .date-en1 {
            width: 98px !important;
        }

        .date-en2 {
            width: 118px !important;
        }

        .date-en3 {
            width: 133px !important;
        }
    }
`;
